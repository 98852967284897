import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useGeneral } from '../../context/GeneralContext';
import { payBudget2 } from '../budget/budgetServices';
import Swal from 'sweetalert2';
import './payModal.css';
import { useBudget } from '../../context/BudgetContext';
import { formatMoney } from '../../helpers/generalhelpers';

const UniqueMethod = ({ budgetToPay, closeBudgetToPay, includeIva }) => {
    const { generalData } = useGeneral();
    const paymentMethods = generalData?.metodos_pago || [];
    const iva = generalData?.iva || 21;

    const {setUpdateList} = useBudget();

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [couponNumber, setCouponNumber] = useState('');
    const [errors, setErrors] = useState({});
    const [tarjetaId, setTarjetaId] = useState(null);

    const subtotal = budgetToPay?.total || 0;
    const calculatedIva = budgetToPay?.impuesto;
    const total = includeIva ? subtotal + calculatedIva : subtotal;

    useEffect(() => {
        if (paymentMethods.length > 0) {
            const tarjeta = paymentMethods.find(method => method.nombre.toLowerCase() === "tarjeta de credito");
            if (tarjeta) {
                setTarjetaId(tarjeta.id);
            }
        }
    }, [paymentMethods]);

    const validateForm = () => {
        const newErrors = {};
        if (!paymentMethod) {
            newErrors.paymentMethod = 'Seleccione un método de pago.';
        }
        if (paymentMethod === tarjetaId && (!couponNumber || !/^\d+$/.test(couponNumber))) {
            newErrors.couponNumber = 'El número de cupón es obligatorio y debe contener solo números.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleConfirm = async () => {
        if (!validateForm()) {
            return;
        }

        try {
            await payBudget2(budgetToPay.id, [{
                monto_total_a_pagar: total,
                metodo_pago: paymentMethod,
                numero_cupon: paymentMethod === tarjetaId ? couponNumber : null,
            }]);

            Swal.fire({
                icon: 'success',
                title: 'Pago realizado',
                text: 'El presupuesto se ha cobrado correctamente.',
                confirmButtonColor: '#3085d6',
            });
            setUpdateList(true);
            closeBudgetToPay();
        } catch (error) {
            const errorMessage = error.response?.data?.system_message || 'Ha ocurrido un error inesperado.';
            setErrors({ general: errorMessage });
        }
    };

    return (
        <div className="pay-modal">
            <div className="payment-methods">
                <h3>Método de Pago</h3>
                <div className="methods-grid" style={{ display: 'flex', justifyContent: 'space-around', gap: '10px' }}>
                    {paymentMethods.map((method) => (
                        <Button
                            key={method.id}
                            label={method.nombre}
                            className={`p-button ${paymentMethod === method.id ? 'p-button-success' : 'p-button-secondary'}`}
                            onClick={() => setPaymentMethod(method.id)}
                        />
                    ))}
                </div>
                {errors.paymentMethod && <small className="p-error">{errors.paymentMethod}</small>}
            </div>

            {paymentMethod === tarjetaId && (
                <div className="coupon-input" style={{ marginTop: '20px' }}>
                    <h3>Ingresar Número de Cupón</h3>
                    <InputText
                        value={couponNumber}
                        onChange={(e) => setCouponNumber(e.target.value)}
                        placeholder="Número de cupón"
                        className={errors.couponNumber ? 'p-invalid' : ''}
                    />
                    {errors.couponNumber && <small className="p-error">{errors.couponNumber}</small>}
                </div>
            )}

            <div className="totals" style={{ marginTop: '20px', textAlign: 'center' }}>
                <h3>Resumen Unique</h3>
                <p>Subtotal: { formatMoney(subtotal?.toFixed(2))}</p>
                {includeIva && <p>IVA: {formatMoney(calculatedIva?.toFixed(2))}</p>}
                <p><strong>Total: { formatMoney(total?.toFixed(2))}</strong></p>
            </div>

            {errors.general && <small className="p-error">{errors.general}</small>}

            <div className="actions" style={{ display: 'flex', justifyContent: 'space-around', gap: '10px' }}>
                <Button label="Cancelar" className="p-button-danger" onClick={closeBudgetToPay} />
                <Button label="Confirmar" className="p-button-success" onClick={handleConfirm} />
            </div>
        </div>
    );
};

export default UniqueMethod;
