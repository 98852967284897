import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'primereact/autocomplete'; // Importar AutoComplete
import { Toast } from 'primereact/toast'; // Importar Toast
import { useBudget } from '../../context/BudgetContext'; // Importar el contexto BudgetContext
import { getArticulos } from '../articles/articlesServices'; // Servicio para buscar artículos

const AddProductInBudget = () => {
    const { addProduct } = useBudget(); // Accede a la función para agregar productos al presupuesto
    const [articles, setArticles] = useState([]); // Estado para almacenar todos los artículos disponibles
    const [filteredArticles, setFilteredArticles] = useState([]); // Estado para almacenar los artículos filtrados
    const [searchQuery, setSearchQuery] = useState(''); // Estado para manejar la búsqueda
    const toast = useRef(null); // Referencia para el Toast

    // Función para obtener todos los artículos al cargar el componente
    const fetchArticles = async () => {
        try {
            const data = await getArticulos(); // Obtén todos los artículos disponibles
            setArticles(data.registros); // Guarda todos los artículos en el estado
            setFilteredArticles(data.registros); // Inicializa los artículos filtrados
        } catch (error) {
            console.error('Error al obtener artículos:', error);
        }
    };

    // Llama a la función fetchArticles al montar el componente
    useEffect(() => {
        fetchArticles();
    }, []);

    // Función para filtrar artículos según el input del usuario
    const searchArticle = (event) => {
        const query = event?.query?.toLowerCase(); // Convierte la consulta a minúsculas
        setSearchQuery(query); // Actualiza el estado con el valor de búsqueda

        // Filtra los artículos que coincidan con el nombre o código
        const filtered = articles?.filter(article =>
            article.descripcion?.toLowerCase().includes(query) ||
            article.sku?.toLowerCase().includes(query)
        );
        setFilteredArticles(filtered); // Actualiza los artículos filtrados
    };

    // Función para manejar la selección de un artículo
    const handleSelect = (article) => {
        addProduct(article); // Agrega el artículo al presupuesto
        setSearchQuery(''); // Limpia el campo después de seleccionar un artículo

        // Muestra la notificación con Toast
        toast.current.show({
            severity: 'success', // Tipo de notificación (success, info, warn, error)
            summary: 'Producto Agregado',
            detail: `${article.descripcion} se agregó al presupuesto.`,
            life: 3000, // Duración en milisegundos
        });
    };

    // Renderizado de cada artículo en la lista
    const renderArticleItem = (article) => {
        return (
            <div
                className="article-item"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '5px 10px',
                }}
            >
                {/* Contenedor para el texto */}
                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '70%' }}>
                    <span style={{ fontWeight: 'bold' }}>{article.descripcion}</span>
                    <small>Código: {article.sku}</small>
                    <small>Costo: ${article.costo.toFixed(2)}</small>
                </div>
            </div>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
            {/* Componente Toast */}
            <Toast ref={toast} position="bottom-right" /> {/* Posición del Toast */}

            <h3>Buscar Producto</h3>

            {/* Componente AutoComplete para buscar artículos */}
            <AutoComplete
                value={searchQuery} // Valor visible en el input
                suggestions={filteredArticles} // Lista de artículos filtrados
                completeMethod={searchArticle} // Método para filtrar artículos
                field="descripcion" // Propiedad que se muestra en las sugerencias
                onChange={(e) => setSearchQuery(e.value)} // Actualiza el texto en el input
                onSelect={(e) => handleSelect(e.value)} // Agrega el artículo y muestra la notificación
                placeholder="Buscar artículo por nombre o código..."
                dropdown
                itemTemplate={renderArticleItem} // Renderiza cada artículo en la lista
                style={{ width: '400px' }} // Ajusta el ancho del AutoComplete
            />
            <p style={{ marginTop: '5px', color: 'gray', fontSize: '0.9rem' }}>
                Buscar por nombre o código
            </p>
        </div>
    );
};

export default AddProductInBudget;
