import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Swal from 'sweetalert2';
import FormExtraction from './FormExtraction';
import ExtractionDetails from './ExtractionDetails';
import { deleteRegistroContable } from './cashServices';
import { useBudget } from '../../context/BudgetContext';
import DiscrepancyFormEdit from '../treasury/cashClosureDetails/DiscrepancyFormEdit';
import DiscrepancyForm from '../treasury/cashClosureDetails/DiscrepancyForm';

const TransactionsListPeriod = ({ allPaymentMethods, registros, fontSize, cash, fetchSummary, cashMount, discrepancies,  closureDetails }) => {

    const { openBudgetDetails } = useBudget();

    const [showExtractionDialog, setShowExtractionDialog] = useState(false);
    const [showDetailsDialog, setShowDetailsDialog] = useState(false);
    const [showDiscrepancyDialog, setShowDiscrepancyDialog] = useState(false);
    const [editingExtraction, setEditingExtraction] = useState(null);
    const [detailsExtraction, setDetailsExtraction] = useState(null);
    const [editingDiscrepancy, setEditingDiscrepancy] = useState(null);

    const handleCancelEditExtraction = () => {
        setShowExtractionDialog(false);
    };

    const handleCloseDetailsDialog = () => {
        setShowDetailsDialog(false);
    };

    const handleCloseDiscrepancyDialog = () => {
        setShowDiscrepancyDialog(false);
    };

    const handleDetailsExtraction = (rowData) => {
        setDetailsExtraction(rowData);
        setShowDetailsDialog(true);
    };

    const handleEditClick = (rowData) => {
        setEditingExtraction(rowData);
        setShowExtractionDialog(true);
    };

    const handleEditDiscrepancy = (rowData) => {
        setEditingDiscrepancy(rowData);
        setShowDiscrepancyDialog(true);
    };

    const handleDeleteClick = async (rowData) => {
        const confirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará la extracción de forma permanente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        });

        if (confirm.isConfirmed) {
            try {
                await deleteRegistroContable(rowData.id);
                Swal.fire('Eliminado', 'La extracción fue eliminada correctamente.', 'success');
                fetchSummary();
            } catch (error) {
                console.error('Error al eliminar la extracción:', error);
                Swal.fire('Error', 'No se pudo eliminar la extracción. Inténtalo de nuevo.', 'error');
            }
        }
    };

    useEffect(() => {
      console.log('====================================');
      console.log(fetchSummary);
      console.log('====================================');
    }, [fetchSummary])

    const handleDeleteDiscrepancy = async (rowData) => {
        const confirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará la discrepancia de forma permanente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        });
    
        if (confirm.isConfirmed) {

    
            try {
                await deleteRegistroContable(rowData.id); // Llama a la función de eliminación
                Swal.fire('Eliminado', 'La discrepancia fue eliminada correctamente.', 'success');
                
                // Verificar si fetchSummary está disponible
                if (typeof fetchSummary === 'function') {
                    fetchSummary(); // Llama a la función para refrescar los datos
                } else {
                    console.warn('fetchSummary no está definido o no es una función');
                }
            } catch (error) {
                console.error('Error al eliminar la discrepancia:', error);
                Swal.fire('Error', 'No se pudo eliminar la discrepancia. Inténtalo de nuevo.', 'error');
            }
        }
    };
    

    const handleDetailsClick = (rowData) => {
        openBudgetDetails(rowData?.presupuesto);
    };

    return (
        <div style={{ width: '100%' }}>
            <h3 style={{ marginBottom: '0', marginLeft: '15px' }}>
                {discrepancies ? 'Discrepancias' : 'Transacciones del Periodo'}
            </h3>
            <DataTable
                style={{ fontSize: fontSize || '100%' }}
                value={registros}
                paginator={!discrepancies}
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage={`No hay ${discrepancies ? 'discrepancias' : 'transacciones'} disponibles.`}
                scrollable
                scrollHeight="40vh"
                rowClassName={(rowData) => {
                    if (discrepancies) {
                        switch (rowData.metodo_pago_nombre) {
                            case 'Efectivo':
                                return 'row-efectivo';
                            case 'Transferencia':
                                return 'row-transferencia';
                            case 'Tarjeta de Credito':
                                return 'row-tarjeta';
                            default:
                                return '';
                        }
                    }
                    return '';
                }}
            >
                <Column field="id" header="ID" style={{ width: '10%' }}></Column>

                {discrepancies && <Column field="motivo" header="Motivo" style={{ width: '30%' }}></Column>}

                {!discrepancies && (
                    <Column
                        field="nombre_cliente"
                        header="Cliente"
                        body={(rowData) => {
                            if (rowData.movimiento === 'EGRESO') {
                                return <span style={{ fontStyle: 'italic', color: 'grey' }}>Extracción</span>;
                            }
                            return rowData.nombre_cliente;
                        }}
                    ></Column>
                )}

                {!discrepancies && (
                    <Column
                        field="movimiento"
                        header="Movimiento"
                        body={(rowData) => {
                            let style = {};
                            let label = rowData.movimiento;
                            let iconClass = '';

                            switch (rowData.movimiento) {
                                case 'INGRESO':
                                    style = { backgroundColor: '#e6eee8', color: '#155724', padding: '0.25rem 0.5rem', borderRadius: '0.25rem', display: 'flex', alignItems: 'center', gap: '0.5rem' };
                                    iconClass = 'pi pi-arrow-right';
                                    break;
                                case 'EGRESO':
                                    style = { backgroundColor: '#f5e5e6', color: '#721c24', padding: '0.25rem 0.5rem', borderRadius: '0.25rem', display: 'flex', alignItems: 'center', gap: '0.5rem' };
                                    iconClass = 'pi pi-arrow-left';
                                    break;
                                case 'RESERVA':
                                    style = { backgroundColor: '#fff9e4', color: '#856404', padding: '0.25rem 0.5rem', borderRadius: '0.25rem', display: 'flex', alignItems: 'center', gap: '0.5rem' };
                                    iconClass = 'pi pi-arrow-down';
                                    break;
                                default:
                                    style = { backgroundColor: '#e2e3e5', color: '#383d41', padding: '0.25rem 0.5rem', borderRadius: '0.25rem', display: 'flex', alignItems: 'center', gap: '0.5rem' };
                                    iconClass = 'pi pi-question';
                            }
                            return (
                                <span style={style}>
                                    <i className={iconClass} style={{ fontSize: '1.2rem' }}></i>
                                    {label}
                                </span>
                            );
                        }}
                    ></Column>
                )}

                <Column
                    field="metodo_pago_nombre"
                    header="Método"
                    body={(rowData) => {
                        let iconClass = '';
                        switch (rowData.metodo_pago_nombre) {
                            case 'Efectivo':
                                iconClass = 'pi pi-wallet';
                                break;
                            case 'Transferencia':
                                iconClass = 'pi pi-download';
                                break;
                            case 'Tarjeta de Credito':
                                iconClass = 'pi pi-credit-card';
                                break;
                            default:
                                iconClass = 'pi pi-question';
                        }
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <i className={iconClass} style={{ fontSize: '1.2rem' }}></i>
                                {rowData.metodo_pago_nombre}
                            </div>
                        );
                    }}
                ></Column>

                <Column
                    field="responsable_usuario"
                    header="Responsable"
                    body={(rowData) => (
                        <span>
                            <i className="pi pi-user" style={{ marginRight: '5px', color: '#21538a' }}></i>
                            {rowData.responsable_usuario}
                        </span>
                    )}
                    style={{ width: '15%' }}
                ></Column>

                <Column
                    field="monto_total"
                    header="Monto"
                    body={(rowData) => `$${rowData?.monto_total.toFixed(2)}`}
                    style={{ width: '15%' }}
                ></Column>

                {!discrepancies && (
                    <Column
                        field="created_at"
                        header="Fecha"
                        body={(rowData) => new Date(rowData.created_at).toLocaleString('es-AR')}
                        style={{ width: '20%' }}
                    ></Column>
                )}

                {/* Columna de acciones para egresos */}
                {cash && (
                    <Column
                        header="Acciones"
                        body={(rowData) => {
                            if (rowData.movimiento === 'INGRESO') {
                                return (
                                    <Button
                                        icon="pi pi-file"
                                        className="p-button-rounded p-button-text"
                                        onClick={() => handleDetailsClick(rowData)}
                                        title="Ver detalles"
                                    />
                                );
                            }
                            if (rowData.movimiento === 'EGRESO') {
                                return (
                                    <>
                                        <Button
                                            icon="pi pi-eye"
                                            className="p-button-rounded p-button-text"
                                            onClick={() => handleDetailsExtraction(rowData)}
                                            title="Ver detalles"
                                        />
                                        <Button
                                            icon="pi pi-pencil"
                                            className="p-button-rounded p-button-text"
                                            onClick={() => handleEditClick(rowData)}
                                            title="Editar"
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-danger p-button-rounded p-button-text"
                                            onClick={() => handleDeleteClick(rowData)}
                                            title="Eliminar"
                                        />
                                    </>
                                );
                            }
                            return null;
                        }}
                    ></Column>
                )}

                {/* Columna adicional para discrepancies */}
                {discrepancies && closureDetails?.estado === "PENDIENTE" && (
                    <Column
                        header="Acciones"
                        body={(rowData) => (
                            <>
                                <Button
                                    icon="pi pi-pencil"
                                    className="p-button-rounded p-button-text white-button"
                                    onClick={() => handleEditDiscrepancy(rowData)}
                                    title="Editar Discrepancia"
                                />
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-rounded p-button-text white-button"
                                    onClick={() => handleDeleteDiscrepancy(rowData)}
                                    title="Eliminar Discrepancia"
                                />
                            </>
                        )}
                    ></Column>
                )}
            </DataTable>

            {/* Modal de Extracción */}
            <Dialog
                visible={showExtractionDialog}
                onHide={handleCancelEditExtraction}
                header="Editar Extracción"
                style={{ width: '400px' }}
            >
                <FormExtraction handleCancelExtraction={handleCancelEditExtraction} avaliableCash={cashMount - editingExtraction?.monto_total} fetchSummary={fetchSummary} previousData={editingExtraction} />
            </Dialog>

            {/* Modal de Detalles */}
            <Dialog
                visible={showDetailsDialog}
                onHide={handleCloseDetailsDialog}
                header="Detalles de Extracción"
                style={{ width: '400px' }}
            >
                <ExtractionDetails data={detailsExtraction} />
            </Dialog>

            {/* Modal de Discrepancia */}
            <Dialog
                visible={showDiscrepancyDialog}
                onHide={handleCloseDiscrepancyDialog}
                header="Editar Discrepancia"
                style={{ width: '400px' }}
            >
                <DiscrepancyFormEdit  closureDetails={closureDetails} handleCancelDiscrepancy={handleCloseDiscrepancyDialog} availablePaymentMethods={allPaymentMethods} editingDiscrepancy={editingDiscrepancy} fetchClosureDetails={fetchSummary} />
            </Dialog>
        </div>
    );
};

export default TransactionsListPeriod;
