import React from 'react';
import CashClosuresList from './CashClosuresList';
import OperatingExpenses from './operatingExpenses/OperatingExpenses';
import Tabs from '../common/Tabs';

const Treasury = () => {
    
    const tabs = [
        { Title: 'Cierres de Caja', Component: <CashClosuresList /> },
        { Title: 'Gastos Operativos', Component: <OperatingExpenses /> },
    ];

    return <Tabs tabs={tabs} />;
};

export default Treasury;
