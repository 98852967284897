import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { useBudget } from '../../context/BudgetContext';
import { useGeneral } from '../../context/GeneralContext';
import PdfContent from './PdfContent';
import { sendToCash, returnToDraft, changeException } from '../budget/budgetServices';
import Swal from 'sweetalert2';
import { InputSwitch } from 'primereact/inputswitch';
import { hasRole } from '../../helpers/usersHelper';
import "jspdf-autotable";

const BudgetPreview = ({ budgetDetails }) => {
  const { closeBudgetDetails, setUpdateList, openBudgetToPay, refreshbudgetDetails } = useBudget();
  const { generalData } = useGeneral();
  const estados_presupuestos = generalData?.estados_presupuestos || [];
  const [showIva, setShowIva] = useState(true);

  const handleDownload = async () => {
    const pdfContent = document.getElementById("pdf-content");
    if (!pdfContent) return;

    // 📌 Reducimos la escala para minimizar la resolución de la imagen
    const canvas = await html2canvas(pdfContent, { scale: 1.5 });

    // 📌 Convertimos la imagen a JPEG con calidad más baja (60%)
    const imgData = canvas.toDataURL("image/jpeg", 0.6);

    const doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        compress: true, // 📌 Activa la compresión nativa de jsPDF
    });

    const pdfWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    
    const imgWidth = pdfWidth;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
    let heightLeft = imgHeight;
    let position = 0;

    while (heightLeft > 0) {
        // 📌 Agregamos la imagen con compresión en escala de grises (opcional)
        doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight, "", "FAST");
        
        heightLeft -= pageHeight;
        position -= pageHeight;

        if (heightLeft > 0) {
            doc.addPage();
        }
    }

    const budgetNumber = budgetDetails?.codigo_presupuesto || "000000";
    doc.save(`Presupuesto ${budgetNumber}.pdf`);
};


  const handleSendToCash = async () => {
    closeBudgetDetails();
    const confirm = await Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Deseas enviar este presupuesto a caja?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, enviar',
      cancelButtonText: 'Cancelar',
    });

    if (confirm.isConfirmed) {
      try {
        await sendToCash(budgetDetails.id);
        setUpdateList(true);
        setTimeout(() => {
          refreshbudgetDetails();
        }, 1500);
      } catch (error) {
        console.error('Error al enviar a caja:', error);
      }
    }
  };

  const handleReject = async () => {
    closeBudgetDetails();
    const confirm = await Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Deseas rechazar este presupuesto y retornarlo a borrador?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, rechazar',
      cancelButtonText: 'Cancelar',
    });

    if (confirm.isConfirmed) {
      try {
        await returnToDraft(budgetDetails.id);
        setUpdateList(true);
        setTimeout(() => {
          refreshbudgetDetails();
        }, 1500);
      } catch (error) {
        console.error('Error al rechazar:', error);
      }
    }
  };

  const handleChangeException = async (previousState) => {
    const action = previousState ? 'Deshabilitar' : 'Habilitar';
    const actionDone = previousState ? 'Deshabilitada' : 'Habilitada';
    closeBudgetDetails();
    const confirm = await Swal.fire({
      title: `¿${action} Excepción?`,
      text:
        action === 'Habilitar'
          ? 'Esta acción permitirá realizar el cobro aunque supere el margen.'
          : 'Esta acción deshabilitará la excepción de cobro.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${action} Excepción`,
      cancelButtonText: 'Cancelar',
    });

    if (confirm.isConfirmed) {
      try {
        await changeException(budgetDetails.id);
        await Swal.fire({
          icon: 'success',
          title: `Excepción ${actionDone}`,
          text: `La excepción ha sido ${actionDone} correctamente.`,
        });
        setUpdateList(true);
        setTimeout(() => {
          refreshbudgetDetails();
        }, 1500);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Hubo un problema al ${action} la excepción.`,
        });
        console.error(`Error al ${action} la excepción:`, error);
      }
    }
  };

  const getEstadoNombre = (id) => {
    const estado = estados_presupuestos.find((e) => e.id === id);
    return estado ? estado.nombre : null;
  };

  const estadoActual = getEstadoNombre(budgetDetails?.estado_presupuesto);

  const handleOpenPayModal = () => {
    if (estadoActual === 'CAJA') {
      openBudgetToPay(budgetDetails.id);
      closeBudgetDetails();
    }
  };

  return (
    <Dialog
      visible={!!budgetDetails}
      onHide={closeBudgetDetails}
      header="Previsualización del Presupuesto"
      style={{ width: '80vw' }}
    >
      {/* Contenedor con scroll para el contenido del PDF */}
      <div
        style={{
          maxHeight: '60vh', // Altura máxima
          overflowY: 'auto', // Scroll vertical
          marginBottom: '20px',
          padding: '10px',
          borderRadius: '5px', // Opcional: bordes redondeados
        }}
      >
        <PdfContent budgetDetails={budgetDetails} showIva={showIva} />
      </div>

      {/* Botón para Mostrar IVA abajo a la izquierda */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-50px', // Ajusta la posición según sea necesario
            left: '10px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <InputSwitch
            checked={showIva}
            onChange={(e) => setShowIva(e.value)}
            tooltip="Incluir IVA"
          />
          <label style={{ fontSize: '14px', color: 'gray' }}>Mostrar IVA</label>
        </div>
      </div>

      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'end' }}>
          {estadoActual === 'BORRADOR' && hasRole(['ADMIN', 'VENDEDOR']) && (
            <Button
              label="Enviar a caja"
              icon="pi pi-wallet"
              onClick={handleSendToCash}
              className="p-button-success p-button-rounded"
            />
          )}

          {estadoActual === 'CAJA' && (
            <>
              {hasRole(['ADMIN', 'CAJA']) && (
                <Button
                  label="Rechazar"
                  icon="pi pi-times"
                  onClick={handleReject}
                  className="p-button-danger p-button-rounded"
                />
              )}
              {hasRole(['ADMIN', 'CAJA']) && (
                <Button
                  label="Cobrar"
                  icon="pi pi-dollar"
                  onClick={handleOpenPayModal}
                  className="p-button-primary p-button-rounded"
                />
              )}
            </>
          )}

          <Button
            label="Descargar PDF"
            onClick={handleDownload}
            icon="pi pi-download"
            className="p-button-primary p-button-rounded"
          />

          <Button
            label="Cerrar"
            onClick={closeBudgetDetails}
            icon="pi pi-times"
            className="p-button-text p-button-secondary"
          />
        </div>
      </div>
    </Dialog>


  );
};

export default BudgetPreview;
