import React, { useEffect, useState } from 'react';
import { getClients } from '../clients/clientsServices'; // Asegúrate de que la ruta sea correcta
import { AutoComplete } from 'primereact/autocomplete'; // Importar AutoComplete de PrimeReact
import { Button } from 'primereact/button'; // Importar Button
import { Dialog } from 'primereact/dialog'; // Importar Dialog
import FormClient from '../clients/FormClient';
import { useBudget } from '../../context/BudgetContext'; // Importar el contexto
import './budgetClient.css';

const BudgetClient = () => {
    const { client, setBudgetClient } = useBudget(); // Accede al estado del cliente y su setter
    const [clients, setClients] = useState([]); // Estado para almacenar los clientes
    const [filteredClients, setFilteredClients] = useState([]); // Estado para almacenar los clientes filtrados
    const [error, setError] = useState(''); // Estado para manejar errores
    const [showCreateDialog, setShowCreateDialog] = useState(false); // Estado para controlar el modal
    const [searchQuery, setSearchQuery] = useState(''); // Estado para manejar la búsqueda del cliente (input del usuario)

    // Función para obtener los clientes
    const fetchClients = async () => {
        try {
            const data = await getClients(); // Llama a la función para obtener los clientes
            setClients(data.registros); // Guarda los clientes en el estado
        } catch (error) {
            console.error('Error al obtener clientes:', error);
            setError('Error al obtener clientes'); // Manejo de errores
        }
    };

    // Llama a la función al montar el componente
    useEffect(() => {
        fetchClients();
    }, []);

    // Función para filtrar los clientes según el input
    const searchClient = (event) => {
        const query = event.query.toLowerCase(); // Convierte la consulta a minúsculas
        setSearchQuery(query); // Actualiza el valor del campo de búsqueda
        const filtered = clients.filter(client => {
            const matchesName = client.nombre?.toLowerCase().includes(query) ||
                client.apellido?.toLowerCase().includes(query) ||
                client.razon_social?.toLowerCase().includes(query);
            const matchesCuil = (client.cuil_dni || client.cuit)?.toLowerCase().includes(query);
            return matchesName || matchesCuil;
        });
        setFilteredClients(filtered); // Actualiza los clientes filtrados
    };

    // Función para abrir el modal de creación
    const openCreateDialog = () => {
        setShowCreateDialog(true); // Cambia el estado para abrir el modal
    };

    // Función para cerrar el modal de creación
    const closeCreateDialog = () => {
        setShowCreateDialog(false); // Cambia el estado para cerrar el modal
    };

    // Función para manejar la selección de un cliente
    const handleClientSelect = (selectedClient) => {
        setBudgetClient(selectedClient); // Establece el cliente seleccionado en el contexto
        setSearchQuery(selectedClient.empresa
            ? selectedClient.razon_social
            : `${selectedClient.nombre} ${selectedClient.apellido}`); // Actualiza el campo de búsqueda con el nombre o razón social
    };

    return (
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'start', alignItems: "start" }}>
            {/* Contenedor para el título y el botón */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <h3>Cliente</h3>
                {!client &&
                    <Button
                        label="Agregar Cliente"
                        icon="pi pi-plus"
                        className="p-button-rounded p-button-success"
                        onClick={openCreateDialog} // Abre el modal
                        style={{ fontSize: '90%', padding: '5px 10px', height: '30px' }} // Tamaño reducido
                    />
                }
            </div>

            {error && <p style={{ color: 'red' }}>{error}</p>} {/* Muestra mensaje de error si existe */}

            {/* Mostrar el AutoComplete o el campo deshabilitado */}
            {!client ? (
                <>
                    {/* Componente AutoComplete para buscar clientes */}
                    <AutoComplete
                        value={searchQuery}  // Mantenemos el valor como un string de búsqueda
                        suggestions={filteredClients}  // Lista de clientes filtrados
                        completeMethod={searchClient}  // Método para filtrar
                        field="razon_social"  // Se usa para empresas
                        onChange={(e) => setSearchQuery(e.value)}  // Cambia el valor de búsqueda
                        onSelect={(e) => handleClientSelect(e.value)}  // Llama a setBudgetClient cuando se selecciona un cliente
                        placeholder="Buscar cliente..."
                        dropdown
                        itemTemplate={(client) => (
                            <div className="client-item">
                                <span>{client.empresa
                                    ? client.razon_social
                                    : `${client.nombre} ${client.apellido}`}</span>
                                <br />
                                <small>{client.empresa ? client.cuit : client.cuil_dni}</small>
                            </div>
                        )}
                        style={{ width: '400px' }} // Ajusta el ancho del AutoComplete
                    />
                    <p style={{ marginTop: '5px', color: 'gray', fontSize: '0.9rem' }}>
                        Buscar por nombre, razón social, DNI, CUIL o CUIT
                    </p>
                </>
            ) : (
                <div style={{ position: 'relative', width: '400px' }}>
                    {/* Input deshabilitado */}
                    <input
                        type="text"
                        value={client.empresa
                            ? client.razon_social
                            : `${client.nombre} ${client.apellido}`} // Muestra el cliente seleccionado
                        disabled
                        style={{
                            width: '100%',
                            padding: '0.5rem',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            fontSize: '14px',
                        }}
                    />
                    {/* Botón para eliminar cliente */}
                    <Button
                        icon="pi pi-times"
                        className="p-button-text p-button-secondary"
                        onClick={() => {
                            setBudgetClient(null); // Limpia el cliente seleccionado
                            setSearchQuery(''); // Limpia el input
                        }}
                        style={{
                            position: 'absolute',
                            right: '5px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            height: '30px',
                        }}
                    />
                </div>
            )}

            {/* Modal para agregar un nuevo cliente */}
            <Dialog style={{ width: '50vw' }} header="Agregar Nuevo Cliente" visible={showCreateDialog} onHide={closeCreateDialog}>
                <FormClient close={closeCreateDialog} refreshClients={fetchClients} />
            </Dialog>
        </div>
    );
};

export default BudgetClient;
