import React, { useState } from 'react';

const Tabs = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0); // Estado para manejar la pestaña activa

    return (
        <div>
            {/* Cabecera de pestañas */}
            <div style={{
                display: 'flex',
                justifyContent: 'start',
                borderBottom: '1px solid #ddd',
                marginBottom: '1rem',
            }}>
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => setActiveTab(index)}
                        style={{
                            padding: '10px 20px',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            border: 'none',
                            borderBottom: activeTab === index ? '3px solid #007bff' : '3px solid transparent',
                            background: 'none',
                            cursor: 'pointer',
                            color: activeTab === index ? '#007bff' : '#333',
                        }}
                    >
                        {tab.Title}
                    </button>
                ))}
            </div>

            {/* Contenido de la pestaña activa */}
            <div>
                {tabs[activeTab]?.Component}
            </div>
        </div>
    );
};

export default Tabs;
