import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { PrimeReactProvider } from 'primereact/api';
import AppSidebar from '../appSidebar/AppSidebar';
import { useAuth } from '../../context/AuthContext';
import './mainView.css';
import SectionHeader from '../common/SectionHeader';
import { useGeneral } from '../../context/GeneralContext';

const MainView = ({ children }) => {
  const { fetchGeneralData } = useGeneral();

  const {headerHeight} = useGeneral()

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isResetPage, setIsResetPage] = useState(false);
  const [isLoginPage, setIsLoginPage] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  // Verificar si estamos en la ruta específica cuando cambia la ubicación
  useEffect(() => {
    setIsResetPage(location.pathname.includes('/reset_password'));
    setIsLoginPage(location.pathname === '/login');
    setIsHomePage(location.pathname === '/');
  }, [location.pathname]);

  useEffect(() => {
    if (!user && !isLoginPage && !isResetPage) {
      navigate('/login');
    } else if (user && isHomePage) {
      navigate('/home');
    }
  }, [user, isLoginPage, isResetPage, isHomePage, navigate]);

  useEffect(() => {
    fetchGeneralData();
  }, [location]);


  return (
    <PrimeReactProvider>
      <div
        className="general-container"
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: !isLoginPage && !isResetPage ? `${headerHeight}px` : "0",
        }}
      >
        {!isLoginPage && !isResetPage && !sidebarVisible && (
          <Button
            icon="pi pi-angle-right"
            onClick={() => setSidebarVisible(true)}
            className="p-button-rounded"
            style={{ position: 'fixed', top: '22px', left: '10px', zIndex: 10000 }}
          />
        )}

        {!isLoginPage && !isResetPage && <SectionHeader />}

        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F9FAFB",
            padding: !isLoginPage && !isResetPage ? "0 1% 0 1%" : "0",
          }}
          className="content"
        >
          {children}
        </div>
      </div>

      {!isLoginPage && !isResetPage && (
        <AppSidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
      )}
    </PrimeReactProvider>
  );
};

export default MainView;
