import React, { useEffect, useState } from 'react';
import CashOptions from './CashOptions';
import { getCurrentPeriodSummary } from './cashServices';
import TransactionsListPeriod from './TransactionsListPeriod';
import PeriodsMountsCash from './PeriodsMountsCash';
import './cash.css';
import { useGeneral } from '../../context/GeneralContext';

const Cash = () => {
    const {headerHeight} = useGeneral()
    const [periodSummary, setPeriodSummary] = useState(null);

    const fetchSummary = async () => { // Mover esta función aquí, antes del useEffect
        try {
            const summary = await getCurrentPeriodSummary();
            setPeriodSummary(summary);
        } catch (error) {
            console.error('Error al obtener el resumen del periodo actual:', error);
        }
    };

    useEffect(() => {
        fetchSummary(); // Ahora fetchSummary estará definido antes de este llamado
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: `calc(100vh - ${headerHeight}px)`,
        }}>
            {periodSummary && (
                <PeriodsMountsCash periodSummary={periodSummary} />
            )}

            <div style={{
                flex: 1,
            }}>
                {periodSummary && <TransactionsListPeriod registros={periodSummary.registros} cash={true} fetchSummary={fetchSummary} cashMount={periodSummary?.efectivo} />}
            </div>

            {periodSummary &&
                <CashOptions avaliableCash={periodSummary.efectivo} fetchSummary={fetchSummary} />
            }
        </div>
    );
};

export default Cash;
