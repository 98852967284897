import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie'; // Importa js-cookie para manejar las cookies
import { getGeneralData } from '../helpers/generalServices';

// Crear el contexto
const GeneralContext = createContext();

// Proveedor del contexto
export const GeneralProvider = ({ children }) => {
    const [generalData, setGeneralData] = useState(null); // Estado para los datos generales
    const [headerHeight, setHeaderHeight] = useState("80");


    const setNavHeight = value => setHeaderHeight(value)

    // Función para obtener los datos generales desde el backend
    const fetchGeneralData = async () => {
        try {
            const data = await getGeneralData(); // Llama a la función para obtener los datos generales
            if (data) {
                setGeneralData(data); // Establecer los datos obtenidos en el estado
                // Guardar los datos en la cookie
                Cookies.set('generalData', JSON.stringify(data), { expires: 7 }); // Expira en 7 días
            }
        } catch (error) {
            console.error('Error al obtener los datos generales:', error);
        }
    };

    // Obtener los datos generales desde la cookie si están disponibles
    useEffect(() => {
        try {
            const cookieData = Cookies.get('generalData');
            if (cookieData) {
                setGeneralData(JSON.parse(cookieData)); // Si hay datos en la cookie, cargarlos en el estado
            } else {
                fetchGeneralData(); // Si no hay cookie, hacer el GET
            }

        } catch (error) {
            console.log(error);

        }
    }, []);

    // Función para actualizar los datos generales y guardarlos en la cookie
    // const updateGeneralData = (newData) => {
    //     setGeneralData(newData); // Actualizar el estado
    //     Cookies.set('generalData', JSON.stringify(newData), { expires: 1 }); // Expira en 1 día
    // };

    // Función para borrar los datos generales y la cookie
    const clearGeneralData = () => {
        setGeneralData(null); // Limpiar el estado
        Cookies.remove('generalData'); // Borrar la cookie
    };

    return (
        <GeneralContext.Provider value={{
            generalData,
            fetchGeneralData,
            // updateGeneralData,
            clearGeneralData, // Proveemos la función para borrar los datos
            setNavHeight,
            headerHeight
        }}>
            {children}
        </GeneralContext.Provider>
    );
};

// Hook para usar el contexto
export const useGeneral = () => {
    return useContext(GeneralContext);
};
