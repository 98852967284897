import api from '../../helpers/axiosConfig';  // Asegúrate de tener tu configuración de axios importada correctamente
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

// Función para crear un presupuesto
export const createBudget = async (budgetData) => {
    console.log(budgetData);
    

    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Realizar la solicitud POST a la API para crear el presupuesto
        const response = await api.post('/presupuestos/', budgetData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            }
        });

        return response.data;  // Devuelve los datos de la respuesta
    } catch (error) {
        throw error;  // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para obtener la lista de presupuestos
// Función para obtener la lista de presupuestos
export const getBudgets = async (page, filters = {}) => {

    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Construcción de los parámetros dinámicos de la URL
        const params = new URLSearchParams();
        params.append('page', page);

        // Agregar filtros si están presentes
        if (filters.codigo_presupuesto) params.append('codigo_presupuesto', filters.codigo_presupuesto);
        if (filters.estado_presupuesto?.length > 0) {
            params.append('estado_presupuesto', filters.estado_presupuesto.join(','));
        }
        if (filters.nombre_completo) params.append('nombre_completo', filters.nombre_completo);
        if (filters.fecha_desde) params.append('fecha_desde', filters.fecha_desde);
        if (filters.fecha_hasta) params.append('fecha_hasta', filters.fecha_hasta);

        // Realizar la solicitud GET con los parámetros dinámicos
        const response = await api.get(`/presupuestos/?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};



// Función para obtener los detalles de un presupuesto por su ID
export const getBudgetDetails = async (budgetId) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Realizar la solicitud GET a la API para obtener los detalles de un presupuesto por ID
        const response = await api.get(`/presupuestos/${budgetId}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            }
        });

        return response.data;  // Devuelve los datos del presupuesto
    } catch (error) {
        throw error;  // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para enviar un presupuesto a caja
export const sendToCash = async (budgetId) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Realizar la solicitud POST a la API para enviar el presupuesto a caja
        const response = await api.post(`/presupuestos/${budgetId}/traspaso_caja/`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            }
        });

        // Mostrar alerta de éxito
        Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: response.data.system_message,
            confirmButtonColor: '#3085d6',
        });

        return response.data;  // Devuelve los datos de la respuesta
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido';

            if (error.response.status === 400 && error.response.data.stock_insuf) {
                // Error de stock insuficiente
                Swal.fire({
                    icon: 'error',
                    title: 'Stock insuficiente',
                    text: error.response.data.stock_insuf.insuficiente_stock,
                    confirmButtonColor: '#d33',
                });
            } else {
                // Otro error de la API
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    confirmButtonColor: '#d33',
                });
            }
        } else {
            // Error genérico
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ha ocurrido un error inesperado',
                confirmButtonColor: '#d33',
            });
        }

        throw error;  // Lanza el error para que pueda ser manejado en el componente si es necesario
    }
};

// Función para retornar un presupuesto a borrador
export const returnToDraft = async (budgetId) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Realizar la solicitud POST a la API para retornar el presupuesto a borrador
        const response = await api.post(`/presupuestos/${budgetId}/retorno_borrador/`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            }
        });

        // Mostrar alerta de éxito
        Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: response.data.system_message,
            confirmButtonColor: '#3085d6',
        });

        return response.data;  // Devuelve los datos de la respuesta
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido';

            if (error.response.status === 400) {
                // Error de estado incorrecto
                Swal.fire({
                    icon: 'error',
                    title: 'Estado incorrecto',
                    text: errorMessage,
                    confirmButtonColor: '#d33',
                });
            } else {
                // Otro error de la API
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    confirmButtonColor: '#d33',
                });
            }
        } else {
            // Error genérico
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ha ocurrido un error inesperado',
                confirmButtonColor: '#d33',
            });
        }

        throw error;  // Lanza el error para que pueda ser manejado en el componente si es necesario
    }
};

// Función para realizar el cobro de un presupuesto
export const payBudget = async (budgetId, paymentData) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Realizar la solicitud POST a la API para el cobro del presupuesto
        const response = await api.post(`/presupuestos/${budgetId}/cobro_presupuesto/`, paymentData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        // Mostrar alerta de éxito
        Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: response.data.system_message,
            confirmButtonColor: '#3085d6',
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido';

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
                confirmButtonColor: '#d33',
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ha ocurrido un error inesperado',
                confirmButtonColor: '#d33',
            });
        }

        throw error; // Lanza el error para que pueda ser manejado en el componente si es necesario
    }
};

// Función para realizar el cobro de un presupuesto con múltiples métodos de pago
export const payBudget2 = async (budgetId, paymentMethods) => {

    console.log(paymentMethods);
    
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Construcción del payload con los métodos de pago
        const paymentData = {
            metodos_pago: paymentMethods, // Debe ser un array con los métodos de pago y sus montos
        };

        console.log('====================================');
        console.log(paymentData);
        console.log('====================================');

        // Realizar la solicitud POST a la API para el cobro del presupuesto
        const response = await api.post(`/presupuestos/${budgetId}/cobro_presupuesto/`, paymentData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        // Mostrar alerta de éxito
        Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: response.data.system_message,
            confirmButtonColor: '#3085d6',
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {

        throw error; // Lanza el error para que pueda ser manejado en el componente si es necesario
    }
};


// Función para alternar una excepción sobre un presupuesto
export const changeException = async (budgetId) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Realizar la solicitud al backend para alternar la excepción
        const response = await api.post(`/presupuestos/${budgetId}/alternar_excepcion/`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            }
        });
        return response.data; // Devuelve la respuesta del backend
    } catch (error) {
        throw error; // Manejo de errores
    }
};

