import React, { useEffect } from "react";
import logo from "../../img/logo_byn.png";
import { useGeneral } from "../../context/GeneralContext";
import { formatMoney } from "../../helpers/generalhelpers";

const PdfContent = ({ budgetDetails, showIva }) => {
    const { generalData } = useGeneral();
    const maxItemsPerPage = 32; // Máximo de artículos por página

    // Divide los productos en grupos de 32 por página
    const totalItems = budgetDetails?.items_presupuesto.length || 0;
    const pages = Math.ceil(totalItems / maxItemsPerPage);
    const itemsPerPage = [];

    for (let i = 0; i < totalItems; i += maxItemsPerPage) {
        itemsPerPage.push(budgetDetails?.items_presupuesto.slice(i, i + maxItemsPerPage));
    }

    useEffect(() => {
        console.log(pages);
    }, [pages]);

    return (
        <div id="pdf-content" style={{ fontFamily: "Arial", fontSize: "0.9vw", backgroundColor: "#ffffff" }}>
            {itemsPerPage.map((items, pageIndex) => (
                <div
                    key={pageIndex}
                    style={{
                        width: "100%",
                        aspectRatio: "1 / 1.414",  // Mantiene la proporción de A4
                        minHeight: "calc(100% * 1.414)",
                        padding: "2vw",
                        borderBottom: "1px solid #ddd",
                        margin: "0 auto"
                    }}
                >
                    {/* Encabezado */}
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                        <div>
                            <img src={logo} alt="Logo" style={{ width: "50px", marginRight: "10px" }} />
                            <h2 style={{ margin: 0 }}>Electro Hogar</h2>
                            <p style={{ margin: "5px 0" }}><strong>Teléfono:</strong> +54 9 3816261923</p>
                            <p style={{ margin: "5px 0" }}><strong>Email:</strong> ventas@electrohogar.com</p>
                            <p style={{ margin: "5px 0" }}><strong>Dirección:</strong> Av Belgrano 1563, S.M. de Tucumán</p>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <h3 style={{ margin: 0 }}>Presupuesto N°: {budgetDetails?.codigo_presupuesto}</h3>
                            <p style={{ margin: "5px 0" }}>Válido hasta: {budgetDetails?.validez}</p>
                            <p style={{ margin: "5px 0" }}>Página {pageIndex + 1}/{pages}</p>
                        </div>
                    </div>

                    {/* Datos del cliente (solo en la primera página) */}
                    {pageIndex === 0 && (
                        <div style={{ marginBottom: "10px", borderTop: "1px solid grey", paddingTop: "10px" }}>
                            <p><strong>Cliente:</strong> {budgetDetails?.cliente_detalles?.empresa ? budgetDetails?.cliente_detalles?.razon_social : `${budgetDetails?.cliente_detalles?.nombre} ${budgetDetails?.cliente_detalles?.apellido}`}</p>
                            <p><strong>Domicilio:</strong> {budgetDetails?.cliente_detalles?.domicilio}</p>
                            <p><strong>Teléfono:</strong> {budgetDetails?.cliente_detalles?.telefono}</p>
                        </div>
                    )}

                    {/* Tabla de productos */}
                    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
                        <thead>
                            <tr style={{ backgroundColor: "#f5f5f5", textAlign: "left" }}>
                                <th style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>SKU - Código</th>
                                <th style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>Descripción</th>
                                <th style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>Presentación</th>
                                <th style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>Precio</th>
                                <th style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>Cantidad</th>
                                <th style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>Importe Final</th>
                                {showIva && <th style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>IVA (%)</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>{item.articulo_sku}</td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>{item.articulo_descripcion}</td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>{item.articulo_presentacion}</td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>{formatMoney(item.precio_unitario)}</td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>{item.cantidad}</td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px", height: "2vw" }}>{formatMoney(item.total)}</td>
                                    {showIva && (
                                        <td style={{ border: "1px solid #ddd", padding: "8px", minHeight: "2vw" }}>
                                            {`$ ${(item.total * (item.articulo_iva / 100)).toFixed(2)} (${item.articulo_iva}%)`}
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Totales (Solo en la última página) */}
                    {pageIndex === pages - 1 && (
                        <div style={{ textAlign: "right", marginTop: "20px" }}>
                            <p>Subtotal: {formatMoney(budgetDetails?.subtotal)}</p>
                            {budgetDetails?.descuento > 0 && <p>Descuento ({budgetDetails?.descuento}%): {formatMoney(budgetDetails?.subtotal - budgetDetails?.total)}</p>}
                            {showIva && <p>IVA: {formatMoney(budgetDetails?.impuesto)}</p>}
                            {showIva ? (
                                <p><strong>Total: {formatMoney(budgetDetails?.total + budgetDetails?.impuesto)}</strong></p>
                            ) : (
                                <p><strong>Total: {formatMoney(budgetDetails?.total)}</strong></p>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default PdfContent;
