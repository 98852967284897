import React, { useEffect, useState, useCallback } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import OperatingExpensesList from './OperatingExpensesList';
import OperatingExpensesFilters from './OperatingExpensesFilters';
import { getOperatingExpenses } from '../treasuryServices';
import FormOperatingExpenses from './FormOperatingExpenses';

const OperatingExpenses = () => {
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;

    const fetchExpenses = useCallback(async (currentFilters = {}, currentPage) => {
        setLoading(true);
        try {
            const data = await getOperatingExpenses({ 
                ...currentFilters, 
                page: currentPage, 
                pageSize: rowsPerPage 
            });
            setExpenses(data.results);
            setTotalRecords(data.total_registros);
        } catch (error) {
            console.error('Error fetching operating expenses:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchExpenses(filters, page);
    }, [filters, page, fetchExpenses]);

    const handleFilter = useCallback((newFilters) => {
        setFilters(newFilters);
        setPage(1);
    }, []);

    const handleReset = useCallback(() => {
        setFilters({});
        setPage(1);
    }, []);

    const handleEditExpense = (expense) => {
        setSelectedExpense(expense);
        setDialogVisible(true);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px' }}>
                <h2 style={{ margin: 0 }}>Gastos Operativos</h2>
                <Button
                    label="Agregar Gasto"
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-success"
                    onClick={() => {
                        setSelectedExpense(null);
                        setDialogVisible(true);
                    }}
                />
            </div>

            <OperatingExpensesFilters onFilter={handleFilter} onReset={handleReset} />

            {loading ? (
                <p>Cargando gastos operativos...</p>
            ) : (
                <OperatingExpensesList
                    expenses={expenses}
                    onEdit={handleEditExpense}
                    refreshList={() => fetchExpenses(filters, page)}
                    totalRecords={totalRecords}
                    setPage={setPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                />
            )}

            <Dialog
                header={selectedExpense ? "Editar Gasto Operativo" : "Agregar Gasto Operativo"}
                visible={dialogVisible}
                style={{ width: '400px' }}
                onHide={() => setDialogVisible(false)}
            >
                <FormOperatingExpenses
                    handleClose={() => setDialogVisible(false)}
                    refreshList={() => {
                        fetchExpenses(filters, page);
                        setDialogVisible(false);
                    }}
                    previousData={selectedExpense}
                />
            </Dialog>
        </div>
    );
};

export default OperatingExpenses;
