import React, { useEffect, useState } from 'react';
import { getUsers } from './usersServices'; // Asegúrate de que la ruta sea correcta
import { DataTable } from 'primereact/datatable'; // Importar DataTable
import { Column } from 'primereact/column'; // Importar Column
import { Button } from 'primereact/button'; // Importar Button
import { Avatar } from 'primereact/avatar'; // Importar Avatar para el ícono de usuario
import { Dialog } from 'primereact/dialog'; // Importar Dialog
import FormUser from './FormUser'; // Importar el componente del formulario de usuario

const Users = () => {
    const [users, setUsers] = useState([]); // Estado para almacenar los usuarios
    const [error, setError] = useState(''); // Estado para manejar errores
    const [showCreateModal, setShowCreateModal] = useState(false); // Estado para controlar la visibilidad del modal

    const addUser = () => setShowCreateModal(true)
    const closeModal = () => setShowCreateModal(false)

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await getUsers(); // Llama a la función para obtener usuarios
                setUsers(data.registros); // Guarda los usuarios en el estado
            } catch (error) {
                console.error('Error al obtener usuarios:', error);
                setError('Error al obtener usuarios'); // Maneja el error
            }
        };

        fetchUsers(); // Llama a la función al montar el componente
    }, []); // El array vacío asegura que se ejecute solo una vez al montarse

    const editUser = (user) => {
        console.log('Edit user:', user); // Aquí puedes agregar la lógica para editar el usuario
    };

    return (
        <div style={{ margin: '0 auto', maxWidth: '60vw' }}>
            <h2 style={{ textAlign: 'center' }}>Usuarios</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>} {/* Muestra mensaje de error si existe */}
            <DataTable value={users} paginator rows={10}>
                <Column
                    body={(rowData) => (
                        <Avatar icon="pi pi-user" className="user-icon" />
                    )}
                />
                <Column field="username" header="Nombre de Usuario" />
                <Column field="email" header="Correo Electrónico" />
                {/* <Column
                    body={rowData => (
                        <Button
                            icon="pi pi-pencil"
                            onClick={() => editUser(rowData)}
                            className='p-button-rounded p-button-primary'
                        />
                    )}
                /> */}
            </DataTable>

            {/* <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: "12px" }}>
                <Button
                    icon="pi pi-plus"
                    label="Crear usuario"
                    className="p-button-rounded p-button-success"
                    onClick={addUser} // Abre el modal
                />
            </div> */}

            {/* Modal para crear un nuevo usuario */}
            {/* <Dialog
                style={{ width: "50vw" }}
                header="Crear Nuevo Usuario"
                visible={showCreateModal}
                onHide={closeModal} 
            >
                <FormUser close={closeModal}/> 
            </Dialog> */}
        </div>
    );
};

export default Users;
