import React from 'react';
import PeriodsCards from '../../common/PeriodsCards';

const PeriodMountsClosure = ({ periodSummary }) => {
    // Calcular el total sumando efectivo, tarjeta de crédito y transferencia
    const totalMount = (periodSummary.efectivo || 0) + 
                  (periodSummary.tarjeta_credito || 0) + 
                  (periodSummary.transferencia || 0);

    const summaryData = [
        { label: "Efectivo", value: periodSummary.efectivo, color: "#7fb3d5" },
        { label: "Tarjeta de Credito", value: periodSummary.tarjeta_credito, color: "#7dcea0" },
        { label: "Transferencia", value: periodSummary.transferencia, color: "#f0b27a" },
        { label: "Extracciones", value: periodSummary.extracciones, color: "#ec7063" },
        { label: "TOTAL", value: totalMount, color: "#5d6d7e" }, 
    ];

    return <PeriodsCards summaryData={summaryData} />;
};

export default PeriodMountsClosure;
