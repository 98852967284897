import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import UniqueMethod from './UniqueMethod';
import MultipleMethods from './MultipleMethods';
import { useBudget } from '../../context/BudgetContext';
import './payModal.css';
import { formatMoney } from "../../helpers/generalhelpers";

const PayModal = () => {
    const { budgetToPay, closeBudgetToPay } = useBudget();
    const [includeIva, setIncludeIva] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null); // 'single' para único, 'multiple' para múltiples

    const handleSelectMethod = (method) => {
        setSelectedMethod(method);
    };

    return (
        <Dialog visible={!!budgetToPay} onHide={closeBudgetToPay} header="Realizar Pago" style={{ width: '50vw' }}>
            {!selectedMethod ? (
                <div className="pay-modal">
                    <div className="iva-toggle">
                        <h3>Agregar IVA</h3>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Checkbox
                                inputId="includeIva"
                                checked={includeIva}
                                onChange={(e) => setIncludeIva(e.checked)}
                            />
                            <label htmlFor="includeIva">Cobrar IVA</label>
                        </div>
                    </div>

                    <div className="totals" style={{ marginTop: '20px', textAlign: 'center' }}>
                        <h3>Resumen</h3>
                        {includeIva && <p>Subtotal: { formatMoney(budgetToPay?.total?.toFixed(2)) }</p>}
                        {includeIva && <p>IVA: { formatMoney(budgetToPay?.impuesto?.toFixed(2))}</p>}
                        {includeIva ?
                        <p><strong>Total:</strong> {formatMoney((budgetToPay?.total + budgetToPay?.impuesto)?.toFixed(2))}</p>
                    :
                            <p><strong>Total:</strong> ${budgetToPay?.total?.toFixed(2)}</p>
                        }
                    </div>

                    <div className="method-selection" style={{ marginTop: '20px', textAlign: 'center' }}>
                        <h3>Seleccione cómo desea cobrar</h3>
                        <Button
                            label="Un método de pago"
                            className="p-button-lg p-button-success"
                            onClick={() => handleSelectMethod('single')}
                            style={{ width: '80%', marginBottom: '10px' }}
                        />
                        <Button
                            label="Múltiples métodos de pago"
                            className="p-button-lg p-button-secondary"
                            onClick={() => handleSelectMethod('multiple')}
                            style={{ width: '80%' }}
                        />
                    </div>
                </div>
            ) : selectedMethod === 'single' ? (
                <UniqueMethod budgetToPay={budgetToPay} closeBudgetToPay={closeBudgetToPay} includeIva={includeIva} />
            ) : (
                <MultipleMethods budgetToPay={budgetToPay} closeBudgetToPay={closeBudgetToPay} includeIva={includeIva} />
            )}
        </Dialog>
    );
};

export default PayModal;
