import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import Swal from 'sweetalert2';
import { createDiscrepancy } from '../treasuryServices';

const DiscrepancyForm = ({ handleCancelDiscrepancy, fetchClosureDetails, id, closureDetails, availablePaymentMethods }) => {
    const [montoReal, setMontoReal] = useState('');
    const [metodoPago, setMetodoPago] = useState(null);
    const [observaciones, setObservaciones] = useState('');
    const [discrepancia, setDiscrepancia] = useState(null);
    const [errors, setErrors] = useState({}); // Estado para los errores por campo

    // Mapear availablePaymentMethods para obtener las opciones con `amount` desde closureDetails
    const metodoPagoOptions = availablePaymentMethods.map((method) => ({
        label: method.name,
        value: method.id.toString(),
        amount:
            method.name === 'Efectivo'
                ? closureDetails.efectivo || 0
                : method.name === 'Transferencia'
                ? closureDetails.transferencia || 0
                : method.name === 'Tarjeta de Crédito'
                ? closureDetails.tarjeta_credito || 0
                : 0,
    }));

    const handleMontoRealChange = (value) => {
        setMontoReal(value);

        // Calcular discrepancia
        if (metodoPago) {
            const selectedMethod = metodoPagoOptions.find((method) => method.value === metodoPago);
            const discrepancia = parseFloat(value) - (selectedMethod?.amount || 0);
            setDiscrepancia(discrepancia);
        }
    };

    const validateFields = () => {
        const newErrors = {};

        if (!metodoPago) newErrors.metodoPago = 'El método de pago es obligatorio.';
        if (!montoReal) newErrors.montoReal = 'El monto real es obligatorio.';
        if (!observaciones) newErrors.observaciones = 'Las observaciones son obligatorias.';

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
    };

    const handleSubmit = async () => {
        if (!validateFields()) return; // Si hay errores, no continuar

        try {
            const data = {
                id,
                monto_real: parseFloat(montoReal),
                metodo_pago: metodoPago,
                observaciones,
            };

            const response = await createDiscrepancy(data);

            if (response.system_status === 'success') {
                Swal.fire('Éxito', response.system_message, 'success');
                handleCancelDiscrepancy();
                fetchClosureDetails();
            } else {
                throw new Error(response.system_message);
            }
        } catch (error) {
            Swal.fire('Error', error.message || 'Ocurrió un error al crear la discrepancia', 'error');
        }
    };

    return (
        <>
            {/* Campo Método de Pago */}
            <div className="p-field">
                <label htmlFor="metodoPago">Método de Pago:</label>
                <Dropdown
                    id="metodoPago"
                    value={metodoPago}
                    options={metodoPagoOptions}
                    onChange={(e) => {
                        setMetodoPago(e.value);
                        setMontoReal('');
                        setDiscrepancia(null);
                        setErrors((prev) => ({ ...prev, metodoPago: '' })); // Limpiar error
                    }}
                    placeholder="Seleccione el método de pago"
                    style={{
                        width: '100%',
                        borderColor: errors.metodoPago ? 'red' : undefined,
                    }}
                    className={errors.metodoPago ? 'p-invalid' : ''}
                />
                {errors.metodoPago && <small className="p-error">{errors.metodoPago}</small>}
                {metodoPago && (
                    <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                        <Badge
                            value={`Monto disponible: $${metodoPagoOptions.find((method) => method.value === metodoPago)?.amount.toFixed(2)}`}
                            style={{
                                backgroundColor:
                                    metodoPago === '1' ? '#7fb3d5' : // Efectivo
                                    metodoPago === '3' ? '#7dcea0' : // Tarjeta de Crédito
                                    metodoPago === '2' ? '#f0b27a' : // Transferencia
                                    'transparent',
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                padding: '0 1rem',
                                borderRadius: '12px',
                                display: 'inline-block',
                            }}
                        />
                    </div>
                )}
            </div>

            {/* Campo Monto Real */}
            {metodoPago && (
                <div className="p-field" style={{ marginTop: '1rem' }}>
                    <label htmlFor="montoReal">Monto Real:</label>
                    <InputText
                        id="montoReal"
                        value={montoReal}
                        onChange={(e) => {
                            handleMontoRealChange(e.target.value);
                            setErrors((prev) => ({ ...prev, montoReal: '' })); // Limpiar error
                        }}
                        placeholder="Ingrese el monto real"
                        type="number"
                        style={{
                            width: '100%',
                            borderColor: errors.montoReal ? 'red' : undefined,
                        }}
                        className={errors.montoReal ? 'p-invalid' : ''}
                    />
                    {errors.montoReal && <small className="p-error">{errors.montoReal}</small>}
                </div>
            )}

            {/* Mostrar Discrepancia */}
            <div style={{ marginTop: '1rem', textAlign: 'center', fontWeight: 'bold' }}>
                <span
                    style={{
                        color: discrepancia < 0 ? 'red' : discrepancia > 0 ? 'green' : 'gray',
                    }}
                >
                    {`Discrepancia: $${(discrepancia ?? 0).toFixed(2)}`}
                </span>
            </div>

            {/* Campo Observaciones */}
            {metodoPago && (
                <div className="p-field" style={{ marginTop: '1rem' }}>
                    <label htmlFor="observaciones">Observaciones:</label>
                    <InputTextarea
                        id="observaciones"
                        value={observaciones}
                        onChange={(e) => {
                            setObservaciones(e.target.value);
                            setErrors((prev) => ({ ...prev, observaciones: '' })); // Limpiar error
                        }}
                        rows={4}
                        placeholder="Ingrese observaciones"
                        style={{
                            width: '100%',
                            borderColor: errors.observaciones ? 'red' : undefined,
                        }}
                        className={errors.observaciones ? 'p-invalid' : ''}
                    />
                    {errors.observaciones && <small className="p-error">{errors.observaciones}</small>}
                </div>
            )}

            {/* Botones */}
            {metodoPago && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        className="p-button-danger"
                        onClick={handleCancelDiscrepancy}
                    />
                    <Button
                        label="Guardar"
                        icon="pi pi-check"
                        className="p-button-success"
                        onClick={handleSubmit}
                    />
                </div>
            )}
        </>
    );
};

export default DiscrepancyForm;
