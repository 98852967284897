import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserComponent from '../appSidebar/UserComponent';
import { menuItems } from '../../helpers/info';
import logo from '../../img/logo.png';
import './sectionHeader.css';
import { Button } from 'primereact/button';
import { useBudget } from '../../context/BudgetContext';
import { useGeneral } from '../../context/GeneralContext';

const SectionHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const { products } = useBudget();
    const { setNavHeight } = useGeneral();

    const headerRef = useRef(null); // 🟢 Referencia al header

    const currentItem = menuItems.find(item => item.route === currentPath);
    const title = currentItem ? currentItem.label : 'Inicio';

    const numberOfProducts = products?.length;

    // 🟢 Detecta cambios de tamaño del header
    useEffect(() => {
        const updateHeight = () => {
            if (headerRef.current) {
                const height = headerRef.current.offsetHeight;
                setNavHeight(height);
            }
        };

        // Usa ResizeObserver para detectar cambios de tamaño
        const observer = new ResizeObserver(() => updateHeight());
        if (headerRef.current) observer.observe(headerRef.current);

        // Actualiza el height al inicio
        updateHeight();

        return () => {
            observer.disconnect(); // Limpieza cuando el componente se desmonta
        };
    }, []);

    return (
        <div id="idHeader" ref={headerRef} // 🟢 Agregamos la referencia aquí
            style={{
                margin: "0",
                padding: "10px 35px 10px 80px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #374151",
                position: "fixed",
                top: "0",
                left: "0",
                right: "0",
                zIndex: "1000",
                backgroundColor: "#ffffff",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                <img className="cursor-pointer logo-img" src={logo} onClick={() => navigate("/")} alt="Logo" />
                <h1 style={{ margin: "0" }}>{title}</h1>
            </div>
            <div>
                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    <UserComponent drop="down" />
                    {currentPath === "/budget" ? (
                        <Button
                            icon="pi pi-list"
                            className="cart-button"
                            onClick={() => navigate("/articles")}
                            title="Artículos"
                        />
                    ) : (
                        <>
                            <Button
                                icon="pi pi-shopping-cart"
                                className="cart-button"
                                onClick={() => navigate("/budget")}
                                title="Presupuesto"
                            />
                            {numberOfProducts > 0 && (
                                <span className="product-count">{numberOfProducts}</span>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SectionHeader;
