import React from 'react';
import OperatingExpensesLogsList from './OperatingExpensesLogsList';
import Tabs from '../common/Tabs';

const Reports = () => {
    const tabs = [
        { Title: 'Logs de Gastos Operativos', Component: <OperatingExpensesLogsList /> },
        // Puedes añadir más pestañas aquí si es necesario
    ];

    return <Tabs tabs={tabs} />;
};

export default Reports;