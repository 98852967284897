import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { getUsers } from '../../users/usersServices';

const OperatingExpensesFilters = ({
    onFilter,
    onReset
}) => {

    const [filters, setFilters] = useState({
        fechaDesde: '',
        fechaHasta: '',
        metodoPago: '',
        motivo: '',
        responsable: '',
    });
    const [tesoreriaUsers, setTesoreriaUsers] = useState([]); // Estado para almacenar los usuarios

    const metodoPagoOptions = [
        { label: 'Efectivo', value: 'EFECTIVO' },
        { label: 'Transferencia', value: 'TRANSFERENCIA' },
        { label: 'Tarjeta de Crédito', value: 'TARJETA_DE_CREDITO' },
    ];

    const fetchUsers = async () => {
        try {
            const data = await getUsers(); // Llama a la función para obtener usuarios

            // Filtrar usuarios que tengan el grupo TESORERIA
            const usersTesoreria = data.registros.filter((user) =>
                user.grupos.some((group) => group.name === 'TESORERO')
            );

            setTesoreriaUsers(usersTesoreria); // Guarda los usuarios filtrados en el estado
        } catch (error) {
            console.error('Error al obtener usuarios:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        onFilter(filters);
    }, [filters, onFilter]);

    const handleReset = () => {
        setFilters({
            fechaDesde: '',
            fechaHasta: '',
            metodoPago: '',
            motivo: '',
            responsable: '',
        });
        onReset();
    };

    return (
        <div className="form-container-transactions" style={{ marginBottom: '20px' }}>
            {/* Filtro Fecha Desde */}
            <div className="form-group-transactions">
                <label className="form-label-transactions" htmlFor="fecha-desde">
                    Fecha Desde
                </label>
                <input
                    id="fecha-desde"
                    type="date"
                    className="input-field-transactions"
                    value={filters.fechaDesde}
                    onChange={(e) =>
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            fechaDesde: e.target.value,
                        }))
                    }
                />
            </div>

            {/* Filtro Fecha Hasta */}
            <div className="form-group-transactions">
                <label className="form-label-transactions" htmlFor="fecha-hasta">
                    Fecha Hasta
                </label>
                <input
                    id="fecha-hasta"
                    type="date"
                    className="input-field-transactions"
                    value={filters.fechaHasta}
                    onChange={(e) =>
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            fechaHasta: e.target.value,
                        }))
                    }
                />
            </div>

            {/* Filtro Método de Pago */}
            <div className="form-group-transactions">
                <label className="form-label-transactions" htmlFor="metodo-pago">
                    Método de Pago
                </label>
                <select
                    id="metodo-pago"
                    className="input-field-transactions"
                    value={filters.metodoPago}
                    onChange={(e) =>
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            metodoPago: e.target.value,
                        }))
                    }
                >
                    <option value="">- TODOS -</option>
                    {metodoPagoOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            {/* Filtro Motivo */}
            <div className="form-group-transactions">
                <label className="form-label-transactions" htmlFor="motivo">
                    Motivo
                </label>
                <input
                    id="motivo"
                    type="text"
                    className="input-field-transactions"
                    value={filters.motivo}
                    onChange={(e) =>
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            motivo: e.target.value,
                        }))
                    }
                    placeholder="Ingrese el motivo"
                />
            </div>

            {/* Filtro Responsable Caja */}
            <div className="form-group-transactions">
                <label className="form-label-transactions" htmlFor="responsable-caja">
                    Responsable Caja
                </label>
                <select
                    id="responsable-caja"
                    className="input-field-transactions"
                    value={filters.responsable}
                    onChange={(e) =>
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            responsable: e.target.value,
                        }))
                    }
                >
                  <option value="">- TODOS -</option>
                        {tesoreriaUsers.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.username}
                            </option>
                        ))}
                </select>
            </div>

            {/* Botón Limpiar Filtros */}
            <div style={{ marginTop: '20px' }}>
                <Button
                    label="Limpiar Filtros"
                    icon="pi pi-filter-slash"
                    className="p-button-secondary"
                    onClick={handleReset}
                    style={{ marginLeft: '10px' }}
                />
            </div>
        </div>
    );
};

export default OperatingExpensesFilters;
