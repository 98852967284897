import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Swal from 'sweetalert2';
import { useAuth } from '../../context/AuthContext';
import { registerExtraction, editExtraction } from './cashServices'; // Importar solo las funciones necesarias

const FormExtraction = ({ handleCancelExtraction, avaliableCash, fetchSummary, previousData }) => {
    const { user } = useAuth(); // Obtener el usuario del contexto
    const userData = user ? user.user_data : null; // Extraer solo user_data

    const [amount, setAmount] = useState('');
    const [reason, setReason] = useState('');
    const [amountError, setAmountError] = useState(false);
    const [exceedsCashError, setExceedsCashError] = useState(false);
    const [reasonError, setReasonError] = useState(false);

    // Inicializar los valores si previousData existe
    useEffect(() => {
        if (previousData) {
            setAmount(Math.abs(previousData.monto_total).toString()); // Convertir a positivo para mostrar
            setReason(previousData.motivo || '');
        }
    }, [previousData]);

    // Obtener la fecha y hora actual
    const currentDateTime = new Date().toLocaleString('es-AR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    const handleConfirmExtraction = async () => {
        let isValid = true;

        const numericAmount = parseFloat(amount);

        // Validar el campo monto
        if (!amount || isNaN(numericAmount) || numericAmount <= 0) {
            setAmountError(true);
            setExceedsCashError(false); // Limpia error de exceso
            isValid = false;
        } else if (numericAmount > avaliableCash) {
            setExceedsCashError(true);
            setAmountError(false);
            isValid = false;
        } else {
            setAmountError(false);
            setExceedsCashError(false);
        }

        // Validar el campo motivo
        if (!reason) {
            setReasonError(true);
            isValid = false;
        } else {
            setReasonError(false);
        }

        if (!isValid) return; // Detener si hay errores

        try {
            if (previousData) {
                // Editar la extracción existente
                await editExtraction(previousData.id, numericAmount, reason);
                Swal.fire('Éxito', 'La extracción fue editada correctamente.', 'success');
            } else {
                // Registrar una nueva extracción
                const extractionData = {
                    monto_extraccion: numericAmount,
                    motivo: reason,
                };
                await registerExtraction(extractionData);
                Swal.fire('Éxito', 'La extracción fue registrada correctamente.', 'success');
            }

            handleCancelExtraction(); // Cerrar el modal
            fetchSummary(); // Actualizar los datos
            setAmount('');
            setReason('');
        } catch (error) {
            console.error('Error al procesar la extracción:', error);
        }
    };

    return (
        <>
            {/* Efectivo Disponible */}
            <div style={{
                backgroundColor: '#e6f7ff', // Azul claro
                padding: '10px',
                borderRadius: '5px',
                marginBottom: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#00509e', // Color azul fuerte
                fontSize: '1.2rem',
            }}>
                Efectivo Disponible: ${parseFloat(avaliableCash).toFixed(2)}
            </div>

            {/* Información del Usuario */}
            <div style={{ textAlign: 'start', marginBottom: '1rem' }}>
                <p><strong>Usuario:</strong> <span style={{ color: 'grey' }}>{userData?.username}</span></p>
                <p><strong>Fecha y hora:</strong> <span style={{ color: 'grey' }}>{currentDateTime}</span></p>
            </div>

            {/* Campo Monto */}
            <div className="field">
                <label htmlFor="amount">Monto:</label>
                <InputText
                    id="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Ingrese el monto"
                    style={{
                        width: '100%',
                        borderColor: amountError || exceedsCashError ? 'red' : undefined,
                    }}
                    className={(amountError || exceedsCashError) ? 'p-invalid' : ''}
                />
                {amountError && <small className="p-error">El monto es obligatorio y debe ser un número mayor a 0.</small>}
                {exceedsCashError && <small className="p-error">El monto no puede superar el efectivo disponible.</small>}
            </div>

            {/* Campo Motivo */}
            <div className="field" style={{ marginTop: '1rem' }}>
                <label htmlFor="reason">Motivo:</label>
                <InputTextarea
                    id="reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    rows={4}
                    placeholder="Ingrese el motivo"
                    style={{
                        width: '100%',
                        borderColor: reasonError ? 'red' : undefined,
                    }}
                    className={reasonError ? 'p-invalid' : ''}
                />
                {reasonError && <small className="p-error">El motivo es obligatorio.</small>}
            </div>

            {/* Botones */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    className="p-button-secondary"
                    onClick={handleCancelExtraction}
                />
                <Button
                    label="Confirmar"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={handleConfirmExtraction}
                />
            </div>
        </>
    );
};

export default FormExtraction;
