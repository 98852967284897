import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie'; // Importamos js-cookie
import { useGeneral } from './GeneralContext';
import { getBudgetDetails } from '../components/budget/budgetServices';
import { getClientById } from '../components/clients/clientsServices';

const BudgetContext = createContext();

export const BudgetProvider = ({ children }) => {
    const { generalData } = useGeneral();
    const [markups, setMarkups] = useState([]);
    const [budgetDetails, setBudgetDetails] = useState(null);
    const [budgetToPay, setBudgetToPay] = useState(null); // Nuevo estado para budgetToPay
    const [client, setClient] = useState(() => {
        const storedClient = Cookies.get('budgetClient');
        return storedClient ? JSON.parse(storedClient) : null;
    });
    const [products, setProducts] = useState(() => {
        const storedProducts = Cookies.get('budgetProducts');
        return storedProducts ? JSON.parse(storedProducts) : [];
    });



    const [updateList, setUpdateList] = useState(false);
    const [discount, setDiscount] = useState(0); // Estado para el descuento

    useEffect(() => {
        setMarkups(generalData?.mark_ups || []);
    }, [generalData]);

    // Guardar el cliente y los productos en cookies cuando cambian
    useEffect(() => {
        Cookies.set('budgetClient', JSON.stringify(client), { expires: 7 });
    }, [client]);

    useEffect(() => {
        console.log('====================================');
        console.log(products);
        console.log('====================================');
        Cookies.set('budgetProducts', JSON.stringify(products), { expires: 7 });
    }, [products]);

    const openBudgetDetails = async (budgetId) => {
        try {
            const data = await getBudgetDetails(budgetId);
            const clientDetails = await getClientById(data.cliente);
            setBudgetDetails({
                ...data,
                cliente_detalles: clientDetails,
            });
        } catch (error) {
            console.error('Error al obtener los detalles del presupuesto o cliente:', error);
        }
    };

    const closeBudgetDetails = () => setBudgetDetails(null);

    const refreshbudgetDetails = () => {
        openBudgetDetails(budgetDetails.id)
    }

    // Función para abrir budgetToPay
    const openBudgetToPay = async (budgetId) => {
        try {
            const data = await getBudgetDetails(budgetId);
            setBudgetToPay(data);
        } catch (error) {
            console.error('Error al obtener el presupuesto para pagar:', error);
        }
    };

    // Función para cerrar budgetToPay
    const closeBudgetToPay = () => setBudgetToPay(null);

    const addProduct = (product) => {
        console.log(product);
        
        const existingProduct = products.find((item) => item.id === product.id);
        console.log(existingProduct);

        if (existingProduct) {
            setProducts((prevProducts) =>
                prevProducts.map((item) =>
                    item.id === product.id
                        ? {
                            ...item,
                            quantity: item.quantity === "" ? 1 : item.quantity + 1,
                            total: parseFloat((item.price * (item.quantity + 1)).toFixed(2)),
                        }
                        : item
                )
            );
        } else {
            const price = parseFloat((product.costo * product.mark_up_1).toFixed(2));
            const total = parseFloat((price * 1).toFixed(2));

            setProducts((prevProducts) => [
                ...prevProducts,
                {
                    ...product,
                    quantity: 1,
                    price,
                    markup: 1,
                    total,
                },
            ]);
        }
    };

    const removeProductUnit = (productId) => {
        setProducts((prevProducts) =>
            prevProducts.map((item) =>
                item.id === productId && item.quantity > 1
                    ? {
                        ...item,
                        quantity: item.quantity - 1,
                        total: parseFloat((item.price * (item.quantity - 1)).toFixed(2)),
                    }
                    : item
            )
        );
    };

    const removeProduct = (productId) => {
        setProducts((prevProducts) => prevProducts.filter((item) => item.id !== productId));
    };

    const setQuantity = ({ product, quantity }) => {
        setProducts((prevProducts) =>
            prevProducts.map((item) =>
                item.id === product.id
                    ? {
                        ...item,
                        quantity,
                        total: parseFloat((item.price * quantity).toFixed(2)),
                    }
                    : item
            )
        );
    };

    const setBudgetClient = (clientData) => {
        setClient(clientData);
    };

    const clearBudget = () => {
        setClient(null);
        setProducts([]);
    };

    // const setProductPrice = (productId, markupId) => {
    //     setProducts((prevProducts) =>
    //         prevProducts.map((item) => {
    //             if (item.id === productId) {
    //                 const markup = markups.find((markup) => markup.id === markupId);
    //                 if (markup) {
    //                     const newPrice = parseFloat((item.costo * (1 + markup.porcentaje / 100)).toFixed(2));
    //                     return {
    //                         ...item,
    //                         price: newPrice,
    //                         total: parseFloat((newPrice * item.quantity).toFixed(2)),
    //                         markup: markupId,
    //                     };
    //                 }
    //             }
    //             return item;
    //         })
    //     );
    // };
    const setProductPrice = (productId, pos) => {
        setProducts((prevProducts) =>
            prevProducts.map((item) => {
                if (item.id === productId) {
                    const markupKey = `mark_up_${pos}`; // Genera dinámicamente la clave correcta
                    const newPrice = item[markupKey] * item.costo; // Accede dinámicamente a la propiedad
                    return {
                        ...item,
                        price: newPrice,
                        total: parseFloat((newPrice * item.quantity).toFixed(2)),
                        markup: pos,
                    };

                }
                return item;
            })
        );
    };

    const setMassivePrice = (pos) => {
        setProducts((prevProducts) =>
            prevProducts.map((item) => {
                const markupKey = `mark_up_${pos+1}`; // Genera dinámicamente la clave correcta
                const newPrice = item[markupKey] * item.costo; // Accede dinámicamente a la propiedad
                const newTotal = parseFloat((newPrice * item.quantity).toFixed(2));
                return {
                    ...item,
                    price: newPrice,
                    markup: pos+1,
                    total: newTotal,
                };
            })
        );
    };

    const getTotal = () => {
        return products.reduce((total, product) => total + product.total, 0).toFixed(2);
    };


    // Función para aplicar el descuento
    const applyDiscount = (percentage) => {
        setDiscount(percentage);
    };

    return (
        <BudgetContext.Provider
            value={{
                client,
                products,
                budgetDetails,
                budgetToPay,
                updateList,
                discount,
                addProduct,
                removeProductUnit,
                removeProduct,
                setBudgetClient,
                setQuantity,
                setMassivePrice,
                getTotal,
                setProductPrice,
                clearBudget,
                openBudgetDetails,
                closeBudgetDetails,
                openBudgetToPay,
                closeBudgetToPay,
                setUpdateList,
                refreshbudgetDetails,
                applyDiscount
            }}
        >
            {children}
        </BudgetContext.Provider>
    );
};

export const useBudget = () => useContext(BudgetContext);
