import React, { useState } from 'react';
import { Button } from "primereact/button";
import { useBudget } from '../../context/BudgetContext';

const ArticleButtons = ({ rowData }) => {

      const {
        addProduct,
        products,
        removeProductUnit,
        removeProduct,
        setQuantity
      } = useBudget();

    const existingProduct = products.find(
        (product) => product.id === rowData.id
    );

    const [quantityError, setQuantityError] = useState(false);

    const handleQuantityChange = (e) => {
        const value = e.target.value;

        if (value === "") {
            setQuantityError(true); // Activar el mensaje de error
            setQuantity({ product: rowData, quantity: "" });
            return;
        }

        const newQuantity = parseInt(value, 10);

        if (!isNaN(newQuantity) && newQuantity > 0) {
            setQuantityError(false); // Desactivar el mensaje de error
            setQuantity({ product: rowData, quantity: newQuantity });
        }
    };

    const handleAddProduct = () => {
        addProduct(rowData);
        setQuantityError(false); // 🔹 Eliminar error si se usa el botón "+"
    };

    const handleRemoveUnit = () => {
        removeProductUnit(rowData.id);
        if (existingProduct && existingProduct.quantity > 1) {
            setQuantityError(false); // 🔹 Si aún hay unidades, eliminar error
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {existingProduct ? (
                <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button
                            icon="pi pi-minus"
                            className="p-button-rounded p-button-danger"
                            onClick={handleRemoveUnit}
                        />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <input
                                type="text"
                                value={existingProduct.quantity}
                                onChange={handleQuantityChange}
                                style={{
                                    width: "50px",
                                    textAlign: "center",
                                    padding: "8px 0px",
                                    margin: "0 2px",
                                    border: quantityError ? "1px solid red" : "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />

                        </div>

                        <Button
                            icon="pi pi-plus"
                            className="p-button-rounded p-button-success"
                            onClick={handleAddProduct}
                        />
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-secondary"
                            onClick={() => removeProduct(rowData.id)}
                            style={{ marginLeft: "1rem" }}
                        />
                    </div>
                    <div style={{ width: "100%", textAlign: "start" }}>
                        {quantityError && (
                            <small style={{ color: "red", marginTop: "4px" }}>
                                Ingrese la cantidad!
                            </small>
                        )}
                    </div>
                </>
            ) : (
                <Button
                    icon="pi pi-cart-plus"
                    className="p-button-rounded p-button-primary"
                    onClick={handleAddProduct}
                    title="Agregar al presupuesto"
                />
            )}
        </div>
    );
};

export default ArticleButtons;
