import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useGeneral } from '../../context/GeneralContext';
import { payBudget2 } from '../budget/budgetServices';
import Swal from 'sweetalert2';
import { useBudget } from '../../context/BudgetContext';
import { formatMoney } from '../../helpers/generalhelpers';
import './payModal.css';

const MultipleMethods = ({ budgetToPay, closeBudgetToPay, includeIva }) => {
    const { generalData } = useGeneral();
    const { setUpdateList } = useBudget();
    const paymentMethods = generalData?.metodos_pago || [];
    const iva = generalData?.iva || 21;

    const subtotal = budgetToPay?.total || 0;
    const calculatedIva = budgetToPay?.impuesto;
    const total = includeIva ? subtotal + calculatedIva : subtotal;

    const tarjetaCredito = paymentMethods.find(method => method.nombre.toLowerCase() === "tarjeta de credito");

    const [payments, setPayments] = useState([]);
    const [remaining, setRemaining] = useState(total);
    const [errors, setErrors] = useState({});

    const handleAddPayment = () => {
        setPayments([...payments, { metodo_pago: null, monto_total_a_pagar: remaining, numero_cupon: '' }]);
    };

    const handleRemovePayment = (index) => {
        const updatedPayments = [...payments];
        updatedPayments.splice(index, 1);
        setPayments(updatedPayments);
        updateRemaining(updatedPayments);
    };

    const updateRemaining = (updatedPayments) => {
        const totalPaid = updatedPayments.reduce((sum, p) => sum + (p.monto_total_a_pagar || 0), 0);
        setRemaining(total - totalPaid);
    };

    const handlePaymentChange = (index, field, value) => {
        const updatedPayments = [...payments];

        if (field === "monto_total_a_pagar") {
            const formattedValue = parseFloat(value).toFixed(2); // 🔹 Limita a 2 decimales
            updatedPayments[index][field] = isNaN(formattedValue) ? 0 : Number(formattedValue);
        } else {
            updatedPayments[index][field] = value;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [index]: { ...prevErrors[index], [field]: null }
        }));

        setPayments(updatedPayments);
        updateRemaining(updatedPayments);
    };

    const validatePayments = () => {
        let newErrors = {};
        payments.forEach((payment, index) => {
            if (!payment.metodo_pago) {
                newErrors[index] = { ...newErrors[index], metodo_pago: 'Seleccione un método de pago' };
            }
            if (!payment.monto_total_a_pagar || payment.monto_total_a_pagar <= 0) {
                newErrors[index] = { ...newErrors[index], monto_total_a_pagar: 'Ingrese un monto válido' };
            }
            if (payment.metodo_pago?.id === tarjetaCredito?.id && (!payment.numero_cupon || !/^\d+$/.test(payment.numero_cupon))) {
                newErrors[index] = { ...newErrors[index], numero_cupon: 'El número de cupón es obligatorio y debe ser numérico' };
            }
        });

        if (remaining !== 0) {
            newErrors.general = 'El total de los pagos debe coincidir con el total a pagar.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleConfirm = async () => {
        if (!validatePayments()) {
            return;
        }

        const formattedPayments = payments.map(p => ({
            metodo_pago: p.metodo_pago?.id,
            monto_total_a_pagar: p.monto_total_a_pagar,
            numero_cupon: p.metodo_pago?.id === tarjetaCredito?.id ? p.numero_cupon : undefined,
        }));

        try {
            await payBudget2(budgetToPay.id, formattedPayments);

            Swal.fire({
                icon: 'success',
                title: 'Pago realizado',
                text: 'El presupuesto se ha cobrado correctamente.',
                confirmButtonColor: '#3085d6',
            });

            setUpdateList(true);
            closeBudgetToPay();
        } catch (error) {
            const errorMessage = error.response?.data?.system_message || 'Ha ocurrido un error inesperado.';
            setErrors({ general: errorMessage });
        }
    };

    return (
        <div className="pay-modal">

            {/* Totales */}
            <div className="totals">
                <div style={{ padding: '10px 15px', backgroundColor: '#f8f9fa', borderRadius: '8px' }}>
                    <p><strong>Subtotal:</strong> {formatMoney(subtotal?.toFixed(2))}</p>
                    {includeIva && <p><strong>IVA:</strong> {formatMoney(calculatedIva?.toFixed(2))}</p>}
                    <p><strong>Total:</strong> {formatMoney(total?.toFixed(2))}</p>
                    <p style={{ color: remaining !== 0 ? 'red' : 'green', fontWeight: 'bold' }}>
                        Monto restante: {formatMoney(remaining?.toFixed(2))}
                    </p>
                </div>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "end", paddingBottom: "15px" }}>
                <Button label="Agregar Método de Pago" icon="pi pi-plus" className="p-button-outlined" onClick={handleAddPayment} />
            </div>

            {/* Lista de métodos de pago */}
            {payments.map((payment, index) => (
                <div key={index} className="payment-entry">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        padding: '10px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        marginBottom: '10px'
                    }}>
                        <Dropdown
                            value={payment.metodo_pago}
                            options={paymentMethods}
                            onChange={(e) => handlePaymentChange(index, 'metodo_pago', e.value)}
                            optionLabel="nombre"
                            placeholder="Seleccionar método"
                            className={errors[index]?.metodo_pago ? 'p-invalid' : ''}
                            style={{ width: '30%' }}
                        />
                        <InputText
                            value={payment.monto_total_a_pagar}
                            onChange={(e) => handlePaymentChange(index, 'monto_total_a_pagar', parseFloat(e.target.value) || 0)}
                            placeholder="Monto"
                            className={errors[index]?.monto_total_a_pagar ? 'p-invalid' : ''}
                            type="number"
                            style={{ width: '20%' }}
                        />

                        {payment.metodo_pago?.id === tarjetaCredito?.id && (
                            <InputText
                                value={payment.numero_cupon}
                                onChange={(e) => handlePaymentChange(index, 'numero_cupon', e.target.value)}
                                placeholder="Número de cupón"
                                className={errors[index]?.numero_cupon ? 'p-invalid' : ''}
                                style={{ width: '20%' }}
                            />
                        )}

                        <Button icon="pi pi-trash" className="p-button-danger" onClick={() => handleRemovePayment(index)} />
                    </div>

                    {errors[index] && <small className="p-error">{Object.values(errors[index]).join(', ')}</small>}
                </div>
            ))}



            {errors.general && <small className="p-error">{errors.general}</small>}

            {/* Botones finales */}
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button label="Cancelar" className="p-button-danger" onClick={closeBudgetToPay} />
                <Button label="Confirmar Pago" className="p-button-success" onClick={handleConfirm} />
            </div>
        </div>
    );
};

export default MultipleMethods;
