import React, { useEffect, useState } from "react";
import { getArticulos } from "./articlesServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import "./articles.css";
import FormArticle from "./FormArticle";
import { useBudget } from "../../context/BudgetContext";
import { useGeneral } from "../../context/GeneralContext";
import { formatMoney } from "../../helpers/generalhelpers";
import { hasRole } from "../../helpers/usersHelper";
import ArticleButtons from "./ArticleButtons";

const Articles = ({ isBudget }) => {
  const { generalData } = useGeneral();
  const {
    products,
    setMassivePrice,
    setProductPrice,
  } = useBudget();
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [skuFilter, setSkuFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [providerFilter, setProviderFilter] = useState("");
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [markups, setMarkups] = useState([]);

  console.log(products);

  const fetchArticulos = async () => {
    try {
      const res = await getArticulos();
      setArticles(res.registros);
      setFilteredArticles(res.registros);
    } catch (error) {
      console.error("Error al obtener los artículos:", error);
    }
  };

  useEffect(() => {
    fetchArticulos();
  }, []);

  useEffect(() => {
    setMarkups(generalData?.mark_ups || []);
  }, [generalData]);

  useEffect(() => {
    const filtered = articles.filter((article) => {
      const matchesSku = article.sku
        .toLowerCase()
        .includes(skuFilter.toLowerCase());
      const matchesDescription = article.descripcion
        .toLowerCase()
        .includes(descriptionFilter.toLowerCase());
      const matchesProvider = article.proveedor_nombre
        .toLowerCase()
        .includes(providerFilter.toLowerCase());
      return matchesSku && matchesDescription && matchesProvider;
    });
    setFilteredArticles(filtered);
  }, [skuFilter, descriptionFilter, providerFilter, articles]);

  const handleCloseCreateDialog = () => setShowCreateDialog(false);
  const handleCloseEditDialog = () => setShowEditDialog(false);


  const handleHeaderClick = (pos) => {
    setMassivePrice(pos);
  };

  return (
    <div style={{ margin: "auto", padding: "10px 0" }}>
      {!isBudget && (
        <div style={{ position: "relative", marginBlock: "10px 20px" }}>
          <h2 style={{ textAlign: "center", margin: 0 }}>Artículos</h2>
          {hasRole(["ADMIN"]) && (
            <div style={{ position: "absolute", right: "1.5%", top: 0 }}>
              <Button
                icon="pi pi-plus"
                label="Crear artículo"
                className="p-button-rounded p-button-success"
                onClick={() => setShowCreateDialog(true)}
              />
            </div>
          )}
        </div>
      )}

      {/* Filtros */}
      {!isBudget && (
        <div className="p-inputgroup" style={{ marginBottom: "20px" }}>
          <InputText
            placeholder="Buscar por SKU"
            className="input-filter-articles"
            value={skuFilter}
            onChange={(e) => setSkuFilter(e.target.value)}
          />
          <InputText
            placeholder="Buscar por Descripción"
            className="input-filter-articles"
            value={descriptionFilter}
            onChange={(e) => setDescriptionFilter(e.target.value)}
          />
          <InputText
            placeholder="Buscar por Proveedor"
            className="input-filter-articles"
            value={providerFilter}
            onChange={(e) => setProviderFilter(e.target.value)}
          />
        </div>
      )}

      <DataTable
        value={isBudget ? products : filteredArticles}
        paginator
        rows={10}
        responsiveLayout="scroll"
      >
        {!isBudget && hasRole(["ADMIN"]) && (
          <Column
            body={(rowData) => (
              <Button
                icon="pi pi-pencil"
                className="p-button-rounded p-button-info"
                onClick={() => {
                  setSelectedArticle(rowData);
                  setShowEditDialog(true);
                }}
              />
            )}
            header="Editar"
            style={{ textAlign: "center", width: "5%" }}
          />
        )}
        <Column field="sku" header="SKU - Código" />
        <Column field="descripcion" header="Descripción" />
        <Column field="proveedor_nombre" header="Proveedor" />
        <Column field="presentacion" header="Presentación" />
        <Column
          field="costo"
          header="Costo"
          body={(rowData) => formatMoney(rowData.costo)}
        />
        {[1, 2, 3].map((num) => (
          <Column
            key={`mark_up_${num}`}
            header={
              <div
                style={{
                  cursor: isBudget ? "pointer" : "default",
                  color: isBudget ? "#007ad9" : "inherit",
                  textDecoration: isBudget ? "underline" : "none",
                }}
                onClick={isBudget ? () => handleHeaderClick(num - 1) : undefined}
              >
                {`Precio ${num}`}
              </div>
            }
            body={(rowData) => {
              const markedPrice = rowData.costo * (1 * rowData[`mark_up_${num}`]);
              const isSelectedPrice = rowData.markup === num;

              return (
                <span
                  className={isSelectedPrice ? "highlight-price" : ""}
                  onClick={() => setProductPrice(rowData.id, num)}
                  style={{ cursor: "pointer" }}
                >
                  {formatMoney(markedPrice)}
                </span>
              );
            }}
          />
        ))}

        <Column field="cantidad_afectada" header="Stock afectado" />
        <Column field="cantidad_disponible" header="Stock disponible" />
        {isBudget && (
          <Column
            header="Total"
            body={(rowData) => formatMoney(rowData.total)}
            style={{ fontWeight: "bold", width: "10%" }}
          />
        )}
        <Column
          body={(rowData) => (
            <ArticleButtons rowData={rowData} />
          )} 
          style={{ textAlign: "center", width: "12%" }}
        />
      </DataTable>

      {/* Dialog para crear artículo */}
      <Dialog
        style={{ width: "40vw" }}
        header="Crear Nuevo Artículo"
        visible={showCreateDialog}
        onHide={handleCloseCreateDialog}
      >
        <FormArticle
          close={handleCloseCreateDialog}
          refreshArticles={fetchArticulos}
        />
      </Dialog>

      {/* Dialog para editar artículo */}
      <Dialog
        style={{ width: "40vw" }}
        header="Editar Artículo"
        visible={showEditDialog}
        onHide={handleCloseEditDialog}
      >
        <FormArticle
          selectedArticle={selectedArticle}
          close={handleCloseEditDialog}
          refreshArticles={fetchArticulos}
        />
      </Dialog>
    </div>
  );
};

export default Articles;
