import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { formatMoney } from '../../helpers/generalhelpers';
import { getTransactions } from '../cash/cashServices';
import { useGeneral } from '../../context/GeneralContext';
import { getUsers } from '../users/usersServices';

const TransactionsList = () => {
    const { generalData } = useGeneral();
    const paymentMethods = generalData?.metodos_pago || []; // Obtener métodos de pago desde generalData

    const [transactions, setTransactions] = useState([]); // Estado para las transacciones
    const [loading, setLoading] = useState(true); // Estado de carga
    const [selectedMethods, setSelectedMethods] = useState([]); // Métodos de pago seleccionados
    const [page, setPage] = useState(1); // Página actual
    const [pageSize, setPageSize] = useState(10); // Tamaño de página
    const [fechaDesde, setFechaDesde] = useState(null); // Fecha desde
    const [fechaHasta, setFechaHasta] = useState(null); // Fecha hasta
    const [usuario, setUsuario] = useState(null); // Usuario seleccionado
    const [tipoMovimiento, setTipoMovimiento] = useState(null); // Tipo de movimiento
    const [totalRecords, setTotalRecords] = useState(0); // Total de registros

    const [cajaUsers, setCajaUsers] = useState([]); // Estado para almacenar los usuarios

    const fetchUsers = async () => {
        try {
            const data = await getUsers(); // Llama a la función para obtener usuarios

            // Filtrar usuarios que tengan el grupo CAJA
            const filtered = data.registros.filter((user) =>
                user.grupos.some((group) => group.name === 'CAJA')
            );

            setCajaUsers(filtered); // Guarda los usuarios filtrados en el estado
        } catch (error) {
            console.error('Error al obtener usuarios:', error);
        }
    };

    const tiposMovimiento = [
        { label: 'Ingreso', value: 'INGRESO' },
        { label: 'Egreso', value: 'EGRESO' },
        { label: 'Reserva Caja', value: 'RESERVA CAJA' },
    ];

    // Función para obtener las transacciones
    const fetchTransactions = async () => {
        try {
            setLoading(true);
            const data = await getTransactions({
                paymentMethods: selectedMethods,
                page,
                pageSize,
                fechaDesde,
                fechaHasta,
                usuario,
                tipoMovimiento,
            });
            setTotalRecords(data.total_registros); // Total de registros
            setTransactions(data.results); // Guardar las transacciones en el estado
        } catch (error) {
            console.error('Error al obtener las transacciones:', error);
        } finally {
            setLoading(false);
        }
    };

    // Efecto para cargar las transacciones al montar el componente
    useEffect(() => {
        fetchTransactions();
    }, [selectedMethods, page, pageSize, fechaDesde, fechaHasta, usuario, tipoMovimiento]);

    // Efecto para inicializar los métodos de pago seleccionados
    useEffect(() => {
        if (paymentMethods.length > 0) {
            setSelectedMethods(paymentMethods.map((method) => method.id)); // Seleccionar todos los métodos de pago por defecto
        }
    }, [paymentMethods]);

    useEffect(() => {
        fetchUsers()
    }, [])

    // Función para manejar cambios en los checkboxes
    const handleCheckboxChange = (methodId) => {
        setSelectedMethods((prevSelected) => {
            if (prevSelected.includes(methodId)) {
                return prevSelected.filter((id) => id !== methodId);
            } else {
                return [...prevSelected, methodId];
            }
        });
    };

    const clearFilters = () => {
        setFechaDesde(null); // Limpiar Fecha Desde
        setFechaHasta(null); // Limpiar Fecha Hasta
        setUsuario(null); // Limpiar Usuario
        setTipoMovimiento(null); // Limpiar Tipo de Movimiento
        setSelectedMethods(paymentMethods.map((method) => method.id)); // Seleccionar todos los métodos de pago por defecto
        setPage(1); // Reiniciar la página a la primera
    };

    return (
        <div style={{ padding: '2rem' }}>
            {/* <h2>Lista de Transacciones</h2> */}

            {/* Filtros de búsqueda */}
            <div className="form-container-budgets" style={{ marginBottom: '20px' }}>
                <div className="form-group-budgets">
                    <label className="form-label-budgets" htmlFor="fecha-desde">
                        Fecha Desde
                    </label>
                    <input
                        id="fecha-desde"
                        type="date"
                        className="input-field-budgets"
                        value={fechaDesde}
                        onChange={(e) => setFechaDesde(e.target.value)}
                    />
                </div>

                <div className="form-group-budgets">
                    <label className="form-label-budgets" htmlFor="fecha-hasta">
                        Fecha Hasta
                    </label>
                    <input
                        id="fecha-hasta"
                        type="date"
                        className="input-field-budgets"
                        value={fechaHasta}
                        onChange={(e) => setFechaHasta(e.target.value)}
                    />
                </div>

                <div className="form-group-budgets">
                    <label className="form-label-budgets" htmlFor="usuario">
                        Buscar por Responsable
                    </label>
                    <select
                        id="usuario"
                        className="input-field-budgets"
                        value={usuario} // Valor actual seleccionado
                        onChange={(e) => setUsuario(e.target.value)} // Manejo de selección
                    >
                        <option value="">- TODOS -</option>
                        {cajaUsers.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.username}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group-budgets">
                    <label className="form-label-budgets" htmlFor="tipo-movimiento">
                        Tipo de Movimiento
                    </label>
                    <select
                        id="tipo-movimiento"
                        className="input-field-budgets"
                        value={tipoMovimiento}
                        onChange={(e) => setTipoMovimiento(e.target.value)}
                    >
                        <option value="">- TODOS -</option>
                        {tiposMovimiento.map((tipo) => (
                            <option key={tipo.value} value={tipo.value}>
                                {tipo.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group-budgets">
                    <label className="form-label-budgets">Método de Pago</label>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {paymentMethods.map((method) => (
                            <div key={method.id} className="custom-row-budgets">
                                <input
                                    type="checkbox"
                                    id={`method-${method.id}`}
                                    checked={selectedMethods.includes(method.id)}
                                    onChange={() => handleCheckboxChange(method.id)}
                                />
                                <label htmlFor={`method-${method.id}`} style={{ marginLeft: '5px' }}>
                                    {method.nombre}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div style={{ marginTop: '20px' }}>
                    <Button
                        label="Limpiar Filtros"
                        icon="pi pi-filter-slash"
                        className="p-button-secondary"
                        onClick={clearFilters}
                        style={{ marginLeft: '10px' }}
                    />
                </div>
            </div>


            {/* Tabla de transacciones */}
            <DataTable
                value={transactions}
                loading={loading}
                paginator
                rows={pageSize}
                first={(page - 1) * pageSize}
                totalRecords={totalRecords}
                onPage={(e) => setPage(e.page + 1)}
                rowsPerPageOptions={[10, 20, 50]}
                lazy
                onRowsPerPageChange={(e) => setPageSize(e.value)}
                emptyMessage="No se encontraron transacciones."
                style={{ width: '100%' }}
                rowClassName={(rowData) => {
                    switch (rowData.movimiento) {
                        case 'INGRESO':
                            return 'row-ingreso';
                        case 'EGRESO':
                            return 'row-egreso';
                        case 'RESERVA CAJA':
                            return 'row-reserva';
                        default:
                            return '';
                    }
                }}
            >
                <Column field="id" header="ID" style={{ width: '10%' }}></Column>
                <Column field="nombre_cliente" header="Cliente" style={{ width: '25%' }}></Column>
                <Column
                    field="movimiento"
                    header="Movimiento"
                    body={(rowData) => {
                        let iconClass = '';
                        switch (rowData.movimiento) {
                            case 'INGRESO':
                                iconClass = 'pi pi-arrow-right';
                                break;
                            case 'EGRESO':
                                iconClass = 'pi pi-arrow-left';
                                break;
                            case 'RESERVA CAJA':
                                iconClass = 'pi pi-arrow-down';
                                break;
                            default:
                                iconClass = 'pi pi-question';
                        }
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <i className={iconClass} style={{ fontSize: '1.2rem' }}></i>
                                {rowData.movimiento}
                            </div>
                        );
                    }}
                    style={{ width: '20%' }}
                ></Column>
                <Column
                    field="metodo_pago_nombre"
                    header="Método de Pago"
                    body={(rowData) => {
                        let iconClass = '';
                        switch (rowData.metodo_pago_nombre) {
                            case 'Efectivo':
                                iconClass = 'pi pi-wallet';
                                break;
                            case 'Transferencia':
                                iconClass = 'pi pi-download';
                                break;
                            case 'Tarjeta de Credito':
                                iconClass = 'pi pi-credit-card';
                                break;
                            default:
                                iconClass = 'pi pi-question';
                        }
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <i className={iconClass} style={{ fontSize: '1.2rem' }}></i>
                                {rowData.metodo_pago_nombre}
                            </div>
                        );
                    }}
                    style={{ width: '20%' }}
                ></Column>
                <Column field="responsable_usuario" header="Responsable" style={{ width: '20%' }}></Column>
                <Column
                    field="monto_total"
                    header="Monto"
                    body={(rowData) => formatMoney(rowData.monto_total)}
                    style={{ width: '15%' }}
                ></Column>
                <Column
                    field="created_at"
                    header="Fecha"
                    body={(rowData) => new Date(rowData.created_at).toLocaleString()}
                    style={{ width: '20%' }}
                ></Column>
            </DataTable>

        </div>
    );
};

export default TransactionsList;
