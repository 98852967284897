import Cookies from 'js-cookie';
import api from '../../helpers/axiosConfig'


// Función para obtener el listado de cierres históricos de caja
export const getClosures = async ({
    page = 1,
    pageSize = 10,
    fechaDesde = null,
    fechaHasta = null,
    responsableCaja = null,
    responsableTesoreria = null
} = {}) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        const params = {
            page,
            page_size: pageSize,
            fecha_desde: fechaDesde || undefined,
            fecha_hasta: fechaHasta || undefined,
            responsable_caja: responsableCaja || undefined,
            responsable_tesoreria: responsableTesoreria || undefined,
        };

        const response = await api.get('/periodo_registro_contable/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            params,
        });

        if (response.status === 200) {
            return response.data; // Retorna los datos de la API
        } else {
            throw new Error('Error inesperado al obtener los cierres de caja.');
        }
    } catch (error) {
        console.error('Error al obtener los cierres de caja:', error);
        throw error;
    }
};

// Función para obtener los detalles de un cierre de caja específico
export const getClosureDetails = async (id) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        const response = await api.get(`/periodo_registro_contable/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        if (response.status === 200) {
            return response.data; // Retorna los datos del cierre de caja
        } else {
            throw new Error('Error inesperado al obtener los detalles del cierre de caja.');
        }
    } catch (error) {
        console.error('Error al obtener los detalles del cierre de caja:', error);
        throw error;
    }
};

// Función para crear una discrepancia en un cierre de caja
export const createDiscrepancy = async ({ id, monto_real, metodo_pago, observaciones }) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        const response = await api.post(`/periodo_registro_contable/${id}/crear_discrepancia/`, {
            monto_real,
            metodo_pago,
            observaciones,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        if (response.status === 201) {
            return response.data; // Retorna los datos de la discrepancia creada
        } else {
            throw new Error('Error inesperado al crear la discrepancia.');
        }
    } catch (error) {
        console.error('Error al crear la discrepancia:', error);
        throw error;
    }
};

// Función para editar una discrepancia en un cierre de caja
export const editDiscrepancy = async (id, { monto_real, motivo }) => {

    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        const response = await api.patch(`/registro_contable/${id}/`, {
            monto_real,
            motivo,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        if (response.status === 206) {
            return response.data; // Retorna los datos de la discrepancia editada
        } else {
            throw new Error('Error inesperado al editar la discrepancia.');
        }
    } catch (error) {
        console.error(error.response);
        console.error('Error al editar la discrepancia:', error);
        throw error;
    }
};


// Función para validar un cierre de caja específico
export const validateClosure = async (id) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        const response = await api.post(`/periodo_registro_contable/${id}/validar_cierre/`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        if (response.status === 200) {
            return response.data; // Retorna los datos de la respuesta
        } else {
            throw new Error('Error inesperado al validar el cierre de caja.');
        }
    } catch (error) {
        console.error('Error al validar el cierre de caja:', error);
        throw error;
    }
};

// Función para obtener la lista de gastos operativos
export const getOperatingExpenses = async ({
    page = 1,
    pageSize = 20,
    fechaDesde = null,
    fechaHasta = null,
    metodoPago = null,
    responsable = null,
    motivo = null,
} = {}) => {
    try {
        // Obtener y validar el token del usuario
        const userdatastring = Cookies.get('user');
        if (!userdatastring) throw new Error('No se pudo obtener los datos del usuario.');

        const userdata = JSON.parse(userdatastring)?.user_data;
        const token = userdata?.token;

        if (!token) {
            throw new Error('Token no disponible. Inicie sesión nuevamente.');
        }

        // Construir parámetros de consulta
        const params = {
            page,
            page_size: pageSize,
            fecha_desde: fechaDesde || undefined,
            fecha_hasta: fechaHasta || undefined,
            metodo_pago: metodoPago || undefined,
            responsable: responsable || undefined,
            motivo: motivo || undefined,
        };

        // Realizar la solicitud GET
        const response = await api.get('/gastos_operativos/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            params, // Pasar los parámetros en la URL
        });

        // Verificar la respuesta y retornar los resultados
        if (response.status === 200) {
            return response.data; // Retorna los datos del API
        } else {
            throw new Error('Error inesperado al obtener los gastos operativos.');
        }
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido.';
            console.error('Error de la API:', errorMessage);
            throw new Error(errorMessage);
        } else {
            console.error('Error inesperado:', error.message);
            throw new Error('Error inesperado al obtener los gastos operativos.');
        }
    }
};

// Función para crear un gasto operativo
export const createOperatingExpense = async (data) => {
    console.log(data)
    try {
        // Obtener y validar el token del usuario
        const userdatastring = Cookies.get('user');
        if (!userdatastring) throw new Error('No se pudo obtener los datos del usuario.');

        const userdata = JSON.parse(userdatastring)?.user_data;
        const token = userdata?.token;

        if (!token) {
            throw new Error('Token no disponible. Inicie sesión nuevamente.');
        }

        // Realizar la solicitud POST
        const response = await api.post('/gastos_operativos/', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        // Verificar la respuesta
        if (response.status === 201) {
            return response.data; // Retorna los datos del gasto creado
        } else {
            throw new Error('Error inesperado al crear el gasto operativo.');
        }
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido.';
            console.error('Error de la API:', errorMessage);
            throw new Error(errorMessage);
        } else {
            console.error('Error inesperado:', error.message);
            throw new Error('Error inesperado al crear el gasto operativo.');
        }
    }
};

// Función para editar un gasto operativo
export const editOperatingExpense = async (id, data) => {

    console.log(data);

    try {
        // Obtener y validar el token del usuario
        const userdatastring = Cookies.get('user');
        if (!userdatastring) throw new Error('No se pudo obtener los datos del usuario.');

        const userdata = JSON.parse(userdatastring)?.user_data;
        const token = userdata?.token;

        if (!token) {
            throw new Error('Token no disponible. Inicie sesión nuevamente.');
        }

        // Realizar la solicitud PATCH
        const response = await api.patch(`/gastos_operativos/${id}/`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        // Verificar la respuesta
        if (response.status === 200) {
            return response.data; // Retorna los datos actualizados del gasto
        } else {
            throw new Error('Error inesperado al editar el gasto operativo.');
        }
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido.';
            console.error('Error de la API:', errorMessage);
            throw new Error(errorMessage);
        } else {
            console.error('Error inesperado:', error.message);
            throw new Error('Error inesperado al editar el gasto operativo.');
        }
    }
};

// Función para eliminar un gasto operativo
export const deleteOperatingExpense = async (id) => {
    try {
        // Obtener y validar el token del usuario
        const userdatastring = Cookies.get('user');
        if (!userdatastring) throw new Error('No se pudo obtener los datos del usuario.');

        const userdata = JSON.parse(userdatastring)?.user_data;
        const token = userdata?.token;

        if (!token) {
            throw new Error('Token no disponible. Inicie sesión nuevamente.');
        }

        // Realizar la solicitud DELETE
        const response = await api.delete(`/gastos_operativos/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        // Verificar la respuesta
        if (response.status === 204) {
            return { system_status: "success", system_message: "Gasto Operativo eliminado exitosamente!" };
        } else {
            throw new Error('Error inesperado al eliminar el gasto operativo.');
        }
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido.';
            console.error('Error de la API:', errorMessage);
            throw new Error(errorMessage);
        } else {
            console.error('Error inesperado:', error.message);
            throw new Error('Error inesperado al eliminar el gasto operativo.');
        }
    }
};
