import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import Swal from 'sweetalert2';
import { editDiscrepancy } from '../treasuryServices';

const DiscrepancyFormEdit = ({ handleCancelDiscrepancy, fetchClosureDetails, closureDetails, availablePaymentMethods, editingDiscrepancy }) => {
    const [montoReal, setMontoReal] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [discrepancia, setDiscrepancia] = useState(null);
    const [errors, setErrors] = useState({}); // Estado para los errores por campo

    const metodoPago = editingDiscrepancy?.metodo_pago?.toString();
    const metodoPagoNombre = editingDiscrepancy?.metodo_pago_nombre;

    const metodoPagoOptions = availablePaymentMethods.map((method) => ({
        label: method.name,
        value: method.id.toString(),
        amount:
            method.name === 'Efectivo'
                ? closureDetails.efectivo || 0
                : method.name === 'Transferencia'
                    ? closureDetails.transferencia || 0
                    : method.name === 'Tarjeta de Crédito'
                        ? closureDetails.tarjeta_credito || 0
                        : 0,
    }));

    const availableAmount = metodoPagoOptions.find((method) => method.value === metodoPago)?.amount || 0;
    const montoDisponible = availableAmount - (editingDiscrepancy?.monto_total || 0);

    useEffect(() => {
        if (editingDiscrepancy && availableAmount) {
            setMontoReal(availableAmount || '');
            setObservaciones(editingDiscrepancy.motivo || '');
        }
    }, [editingDiscrepancy, availableAmount]);

    useEffect(() => {
        if (montoReal) {
            const discrepancyValue = parseFloat(montoReal) - montoDisponible;
            setDiscrepancia(discrepancyValue);
        }
    }, [montoReal, montoDisponible]);

    const validateFields = () => {
        const newErrors = {};

        if (!montoReal) newErrors.montoReal = 'El monto real es obligatorio.';
        if (!observaciones) newErrors.observaciones = 'Las observaciones son obligatorias.';

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
    };

    const handleSubmit = async () => {
        if (!validateFields()) return; // Si hay errores, no continuar

        try {
            const data = {
                monto_real: parseFloat(montoReal),
                motivo: observaciones, // Cambiado a `motivo` según la nueva API
            };

            const response = await editDiscrepancy(editingDiscrepancy.id, data);

            if (response.id) {
                Swal.fire('Éxito', 'Discrepancia actualizada correctamente.', 'success');
                handleCancelDiscrepancy();
                fetchClosureDetails();
            } else {
                throw new Error('Error inesperado al editar la discrepancia.');
            }
        } catch (error) {
            Swal.fire('Error', error.message || 'Ocurrió un error al editar la discrepancia.', 'error');
        }
    };

    return (
        <>
            {/* Campo Método de Pago */}
            <div className="p-field">
                <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                    <Badge
                        value={`${metodoPagoNombre}`}
                        style={{
                            backgroundColor:
                                metodoPago === '1' ? '#7fb3d5' : // Efectivo
                                    metodoPago === '3' ? '#7dcea0' : // Tarjeta de Crédito
                                        metodoPago === '2' ? '#f0b27a' : // Transferencia
                                            'transparent',
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            padding: '0 1rem',
                            borderRadius: '12px',
                            display: 'inline-block',
                        }}
                    />
                </div>
                <div style={{ marginTop: '5px', textAlign: 'center' }}>
                    <Badge
                        value={`Monto Disponible: $${montoDisponible.toFixed(2)}`}
                        style={{
                            backgroundColor:
                                metodoPago === '1' ? '#7fb3d5' : // Efectivo
                                    metodoPago === '3' ? '#7dcea0' : // Tarjeta de Crédito
                                        metodoPago === '2' ? '#f0b27a' : // Transferencia
                                            'transparent',
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            padding: '0 1rem',
                            borderRadius: '12px',
                            display: 'inline-block',
                        }}
                    />
                </div>
            </div>

            {/* Campo Monto Real */}
            <div className="p-field" style={{ marginTop: '1rem' }}>
                <label htmlFor="montoReal">Monto Real:</label>
                <InputText
                    id="montoReal"
                    value={montoReal}
                    onChange={(e) => {
                        setMontoReal(e.target.value);
                        setErrors((prev) => ({ ...prev, montoReal: '' })); // Limpiar error
                    }}
                    placeholder="Ingrese el monto real"
                    type="number"
                    style={{
                        width: '100%',
                        borderColor: errors.montoReal ? 'red' : undefined,
                    }}
                    className={errors.montoReal ? 'p-invalid' : ''}
                />
                {errors.montoReal && <small className="p-error">{errors.montoReal}</small>}
            </div>

            {/* Mostrar Discrepancia */}
            <div style={{ marginTop: '1rem', textAlign: 'center', fontWeight: 'bold' }}>
                <span
                    style={{
                        color: discrepancia < 0 ? 'red' : discrepancia > 0 ? 'green' : 'gray',
                    }}
                >
                    {`Discrepancia: $${(discrepancia ?? 0).toFixed(2)}`}
                </span>
            </div>

            {/* Campo Observaciones */}
            <div className="p-field" style={{ marginTop: '1rem' }}>
                <label htmlFor="observaciones">Observaciones:</label>
                <InputTextarea
                    id="observaciones"
                    value={observaciones}
                    onChange={(e) => {
                        setObservaciones(e.target.value);
                        setErrors((prev) => ({ ...prev, observaciones: '' })); // Limpiar error
                    }}
                    rows={4}
                    placeholder="Ingrese observaciones"
                    style={{
                        width: '100%',
                        borderColor: errors.observaciones ? 'red' : undefined,
                    }}
                    className={errors.observaciones ? 'p-invalid' : ''}
                />
                {errors.observaciones && <small className="p-error">{errors.observaciones}</small>}
            </div>

            {/* Botones */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={handleCancelDiscrepancy}
                />
                <Button
                    label="Guardar"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={handleSubmit}
                />
            </div>
        </>
    );
};

export default DiscrepancyFormEdit;
