import React from 'react';
import { Card } from 'primereact/card'; // Asegúrate de tener importado el componente Card de PrimeReact
import { useNavigate } from 'react-router-dom';
import { menuItems } from '../../helpers/info';

const HomeItemsContainer = () => {
    const navigate = useNavigate();


    return (
        <div className="home-items-container">
            <h2 className="module-title">Módulos</h2>
            <div className="grid">
                {menuItems.map((item, index) => (
                    <div key={index} className="col-3">
                        <Card className="item-card" onClick={() => navigate(item.route)}>

                            <div className="icon-container" style={{ backgroundColor: item.color, borderRadius: '50%' }}>
                                <i className={item.icon} style={{ fontSize: '1.1vw', color: '#ffffff' }}></i> {/* Icono en blanco */}
                            </div>

                            <h2>{item.label}</h2>
                            <p>{item.description}</p>
                        </Card>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeItemsContainer;
