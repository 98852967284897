import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2';
import FormExtraction from './FormExtraction'; // Manteniendo el modal de extracción
import { useGeneral } from '../../context/GeneralContext';
import { Dialog } from 'primereact/dialog';
import { closePeriod } from './cashServices';

const CashOptions = ({ avaliableCash, fetchSummary }) => {
    const { generalData } = useGeneral();
    const [showExtractionDialog, setShowExtractionDialog] = useState(false); // Modal de extracción

    useEffect(() => {
        console.log(generalData);
    }, [generalData]);

    const handleExtraction = () => {
        setShowExtractionDialog(true); // Abrir modal de extracción
    };

    const handleCancelExtraction = () => {
        setShowExtractionDialog(false); // Cerrar modal de extracción
    };

    const handleCloseCashRegister = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¿Quieres cerrar la caja?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cerrar caja',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await closePeriod(); // Llama a la función de cierre
                    Swal.fire('Éxito', response.system_message, 'success');
                    fetchSummary(); // Actualiza el resumen después del cierre
                } catch (error) {
                    Swal.fire('Error', error.message, 'error');
                }
            }
        });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: "70vw", margin: '15px 0' }}>
                <Button
                    style={{ width: "200px" }}
                    label="Extracción"
                    icon="pi pi-minus"
                    className="p-button-secondary p-button-rounded"
                    onClick={handleExtraction}
                />

                <Button
                    style={{ width: "200px" }}
                    label="Cierre de Caja"
                    icon="pi pi-lock"
                    className="p-button-primary p-button-rounded"
                    onClick={handleCloseCashRegister}
                />
            </div>

            {/* Modal de Extracción */}
            <Dialog
                visible={showExtractionDialog} 
                onHide={handleCancelExtraction} 
                header="Registro de Extracción" 
                style={{ width: '400px' }}
            >
                <FormExtraction handleCancelExtraction={handleCancelExtraction} avaliableCash={avaliableCash} fetchSummary={fetchSummary}/>
            </Dialog>
        </div>
    );
};

export default CashOptions;
