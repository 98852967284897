// src/app/services/articlesServices.js

// Importar api y Cookies
import api from '../../helpers/axiosConfig'; // Asegúrate de que la ruta sea correcta
import Cookies from 'js-cookie'; // Importa js-cookie

// Función para obtener los artículos
export const getArticulos = async () => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.get('/articulos/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para editar un artículo
export const editArticle = async (id, data) => {

    console.log(data);

    const newData = {
        descripcion: data.descripcion,
        iva: data.iva,
        costo: data.costo,
        cantidad_disponible: data.cantidad_disponible,
        mark_up_1: data.mark_up_1 ? 1 + data.mark_up_1 / 100 : 1.0,
        mark_up_2: data.mark_up_2 ? 1 + data.mark_up_2 / 100 : 1.0,
        mark_up_3: data.mark_up_3 ? 1 + data.mark_up_3 / 100 : 1.0
    }
    

    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.patch(`/articulos/${id}/`, newData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response; // Devuelve los datos de la respuesta
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para crear un artículo
export const createArticle = async (data) => {
    try {
        const newData = { 
            ...data,
            presentacion: data.presentacion.id,
            proveedor: data.proveedor.id,
            costo: data.costo,
            cantidad_disponible: parseInt(data.cantidad_disponible, 10),
            cantidad_afectada: 0,
            mark_up_1: data.mark_up_1 ? 1 + data.mark_up_1 / 100 : 1.0,
            mark_up_2: data.mark_up_2 ? 1 + data.mark_up_2 / 100 : 1.0,
            mark_up_3: data.mark_up_3 ? 1 + data.mark_up_3 / 100 : 1.0
        };

        console.log('Datos enviados:', newData);

        const userdatastring = Cookies.get('user');
        if (!userdatastring) throw new Error('No se encontró la sesión del usuario.');

        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata?.token;

        if (!token) throw new Error('Token no disponible. Inicie sesión nuevamente.');

        const response = await api.post('/articulos/', newData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        });

        return response; // Retorna solo los datos de la respuesta
    } catch (error) {
        console.error('Error en la creación del artículo:', error.response?.data || error.message);
        throw error; // Lanza el error para manejo en el componente
    }
};



// Función para eliminar un artículo
export const deleteArticle = async (id) => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.delete(`/articulos/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response; // Devuelve la respuesta en caso de éxito
    } catch (error) {
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};

// Función para obtener las presentaciones de los artículos
export const getPresentacionesArticulos = async () => {
    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible'); // Manejo si el token no está disponible
        }

        const response = await api.get('/presentacion_articulos/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`, // Incluir el token de autorización
            }
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        console.log(error.response);
        
        throw error; // Lanza el error para que pueda ser manejado en el componente
    }
};