import React from 'react';

const ExtractionDetails = ({ data }) => {
    if (!data) {
        return <p>No hay datos disponibles.</p>;
    }
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.5' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div>
                    <strong>ID:</strong> <span>{data.id}</span>
                </div>
                <div>
                    <strong>Monto Total:</strong> <span style={{ color: data.monto_total < 0 ? 'red' : 'green' }}>
                        ${Math.abs(data.monto_total).toFixed(2)}
                    </span>
                </div>
                <div>
                    <strong>Método de Pago:</strong> <span>{data.metodo_pago_nombre}</span>
                </div>
                <div>
                    <strong>Motivo:</strong> <span>{data.motivo}</span>
                </div>
                <div>
                    <strong>Movimiento:</strong> <span>{data.movimiento}</span>
                </div>
                <div>
                    <strong>Responsable:</strong> <span>{data.responsable_usuario}</span>
                </div>
                {data.responsable_edicion_usuario && (
                    <div>
                        <strong>Responsable de Edición:</strong> <span>{data.responsable_edicion_usuario}</span>
                    </div>
                )}
                <div>
                    <strong>Fecha de Creación:</strong> <span>{new Date(data.created_at).toLocaleString('es-AR')}</span>
                </div>
                {data.presupuesto && (
                    <div>
                        <strong>Presupuesto Asociado:</strong> <span>{data.presupuesto}</span>
                    </div>
                )}
                {data.nombre_cliente && (
                    <div>
                        <strong>Cliente Asociado:</strong> <span>{data.nombre_cliente}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExtractionDetails;
