import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getClosureDetails } from '../treasuryServices';
import PeriodMountsClosure from './PeriodMountsClosure';
import TransactionsListPeriod from '../../cash/TransactionsListPeriod';
import ClosureOptions from './Closureoptions';
import CashClosureHeader from './CashClosureHeader';
import { useGeneral } from '../../../context/GeneralContext';

const CashClosureDetails = () => {
    const {headerHeight} = useGeneral()

    const { id } = useParams(); // Obtener el ID de la ruta
    const [closureDetails, setClosureDetails] = useState(null); // Estado para los detalles del cierre de caja
    const [loading, setLoading] = useState(true); // Estado de carga

    // Función para cargar los detalles del cierre de caja
    const fetchClosureDetails = async () => {
        try {
            const data = await getClosureDetails(id); // Reemplaza con tu función para obtener los detalles
            setClosureDetails(data);
        } catch (error) {
            console.error('Error al obtener los detalles del cierre de caja:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClosureDetails();
    }, [id]);

    useEffect(() => {
        console.log(closureDetails);
    }, [closureDetails]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (!closureDetails) {
        return <div>No se encontraron detalles para este cierre de caja.</div>;
    }

    // Lista de todos los métodos de pago disponibles
    const allPaymentMethods = [
        { id: 1, name: 'Efectivo' },
        { id: 2, name: 'Transferencia' },
        { id: 3, name: 'Tarjeta de Credito' },
    ];

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: `calc(100vh - ${headerHeight}px)`,
        }}>

            <CashClosureHeader closureDetails={closureDetails} />

            <PeriodMountsClosure periodSummary={closureDetails} />

            {/* Contenedor para las dos tablas */}
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                marginTop: '20px',
                overflow: 'hidden',
            }}>
                {/* Primera tabla */}
                <div style={{ flex: 1, borderRadius: '5px', overflow: 'auto' }}>
                    {closureDetails && <TransactionsListPeriod registros={closureDetails.registros} fontSize="90%" />}
                </div>

                {/* Segunda tabla */}
                <div style={{ flex: 1, borderRadius: '5px', overflow: 'auto' }}>
                    {closureDetails && <TransactionsListPeriod registros={closureDetails.discrepancias_list} fontSize="90%" discrepancies={true} fetchSummary={fetchClosureDetails} allPaymentMethods={allPaymentMethods} closureDetails={closureDetails}/>}
                </div>
            </div>

            {/* Opciones de cierre */}
            {closureDetails.estado === "PENDIENTE" &&
                <div style={{ marginTop: '20px' }}>
                    <ClosureOptions allPaymentMethods={allPaymentMethods} fetchClosureDetails={fetchClosureDetails} id={closureDetails.id} closureDetails={closureDetails} discrepancies={closureDetails.discrepancias_list} />
                </div>
            }
        </div>
    );
};

export default CashClosureDetails;
