export const menuItems = [
    { label: 'Artículos', icon: 'pi pi-list', route: '/articles', description: 'Alta, baja y modificación de artículos', color: '#6f42c1' }, // Morado
    { label: 'Presupuesto', icon: 'pi pi-shopping-cart', route: '/budget', description: 'Creación de presupuestos', color: '#e83e8c' }, // Rosa
    { label: 'Listado de Presupuestos', icon: 'pi pi-folder-open', route: '/budgets-list', description: 'Listado y detalles de presupuestos', color: '#007bff' }, // Azul
    // { label: 'Órdenes de Compra', icon: 'pi pi-file', route: '/purchase-orders', description: 'Creación de órdenes de compra', color: '#fd7e14' }, // Naranja
    // { label: 'Notas de Recibo', icon: 'pi pi-file-arrow-up', route: '/receipt-notes', description: 'Visualización de notas de recibo', color: '#20c997' }, // Verde
    // { label: 'Gastos', icon: 'pi pi-dollar', route: '/expenses', description: 'Registro de gastos', color: '#ffc107' }, // Amarillo
    { label: 'Caja', icon: 'pi pi-wallet', route: '/cash', description: 'Control de caja', color: '#17a2b8' }, // Cian
    { label: 'Tesorería', icon: 'pi pi-inbox', route: '/treasury', description: 'Administración de tesorería', color: '#6610f2' }, // Indigo
    { label: 'Informes', icon: 'pi pi-chart-line', route: '/reports', description: 'Generación de informes', color: '#28a745' }, // Verde oscuro
    { label: 'Usuarios', icon: 'pi pi-user', route: '/users', description: 'Gestión de usuarios', color: '#dc3545' }, // Rojo
    { label: 'Clientes', icon: 'pi pi-users', route: '/clients', description: 'Gestión de clientes', color: '#6c757d' }, // Gris
    { label: 'Proveedores', icon: 'pi pi-truck', route: '/providers', description: 'Gestión de proveedores', color: '#9b59b6' }, // Azul

    { label: 'Movimientos', icon: 'pi pi-list', route: '/transactions', description: 'Listado y detalles de movimientos', color: '#6610f2' } // Indigo
];
