import React from 'react';
import { useBudget } from '../../context/BudgetContext';
// import PayModal from '../payModal/PayModal';
import PayModal from '../payModal/PayModal';
import BudgetPreview from '../budgetPreview/BudgetPreview';

const ModalContainer = () => {

    const { budgetToPay, budgetDetails } = useBudget()

    return (
        <>
            {budgetToPay &&
                <PayModal />
            }

            {budgetDetails &&
                <BudgetPreview budgetDetails={budgetDetails} />
            }

        </>
    );
};

export default ModalContainer;