import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { getOperatingExpensesLogs } from './reportsServices';

const OperatingExpensesLogsList = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

    const fetchLogs = async () => {
        setLoading(true);
        try {
            const params = {
                page: pagination.page,
                page_size: pagination.pageSize,
            };
            const response = await getOperatingExpensesLogs(params);
            setLogs(response.results || []);
            setTotalRecords(response.count || 0);
        } catch (error) {
            console.error('Error al obtener los logs:', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLogs();
    }, [pagination]);

    const onPageChange = (event) => {
        setPagination({ page: event.page + 1, pageSize: event.rows });
    };

    return (
        <div>
            <h2>Logs de Gastos Operativos</h2>
            <DataTable
                value={logs}
                paginator
                rows={pagination.pageSize}
                totalRecords={totalRecords}
                onPage={onPageChange}
                loading={loading}
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No hay logs de gastos operativos disponibles."
                responsiveLayout="scroll"
            >
                <Column
                    field="gasto_operativo"
                    header="ID Gasto"
                    body={(rowData) => (
                        <span style={{ fontWeight: 'bold', color: '#21538a' }}>
                            {rowData.gasto_operativo}
                        </span>
                    )}
                    style={{ width: '10%' }}
                ></Column>
                <Column field="detalle" header="Detalle" style={{ width: '30%' }}></Column>
                <Column
                    field="responsable_usuario"
                    header="Responsable"
                    body={(rowData) => (
                        <span>
                            <i className="pi pi-user" style={{ marginRight: '5px', color: '#21538a' }}></i>
                            {rowData.responsable_usuario}
                        </span>
                    )}
                    style={{ width: '20%' }}
                ></Column>
                <Column
                    field="created_at"
                    header="Fecha Creación"
                    body={(rowData) => new Date(rowData.created_at).toLocaleString('es-AR')}
                    style={{ width: '20%' }}
                ></Column>
                <Column
                    field="updated_at"
                    header="Fecha Actualización"
                    body={(rowData) => new Date(rowData.updated_at).toLocaleString('es-AR')}
                    style={{ width: '20%' }}
                ></Column>
            </DataTable>
        </div>
    );
};

export default OperatingExpensesLogsList;
