import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { useBudget } from '../../context/BudgetContext';
import './clients.css';
import { getClients, activateAccount } from './clientsServices';
import CcForm from './CcForm';
import FormClient from './FormClient'; // Importar el componente FormClient
import Swal from 'sweetalert2';

const Clients = () => {
    const { setBudgetClient } = useBudget();
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [cuilFilter, setCuilFilter] = useState('');
    const [showAccountDialog, setShowAccountDialog] = useState(false);
    const [showClientDialog, setShowClientDialog] = useState(false); // Modal para crear o editar clientes
    const [selectedClient, setSelectedClient] = useState(null);

    const fetchClients = async () => {
        try {
            const res = await getClients();
            setClients(res.registros);
            setFilteredClients(res.registros);
        } catch (error) {
            console.error('Error al obtener los clientes:', error);
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        const filtered = clients.filter(client => {
            const matchesName = client.nombre?.toLowerCase().includes(nameFilter.toLowerCase()) || client.razon_social?.toLowerCase().includes(nameFilter.toLowerCase());
            const matchesCuil = (client.cuil_dni || client.cuit)?.toLowerCase().includes(cuilFilter.toLowerCase());
            return matchesName && matchesCuil;
        });
        setFilteredClients(filtered);
    }, [nameFilter, cuilFilter, clients]);

    const openAccountDialog = (client) => {
        setSelectedClient(client);
        setShowAccountDialog(true);
    };

    const handleCloseAccountDialog = () => {
        setShowAccountDialog(false);
        setSelectedClient(null);
    };

    const handleActivateAccount = async (data) => {
        if (data.error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: data.message,
            });
            return;
        }

        try {
            await activateAccount(selectedClient.id, data);
            Swal.fire({
                icon: 'success',
                title: 'Cuenta Corriente Activada',
                text: '¡Cuenta corriente activada con éxito!',
            });
            fetchClients(); // Actualizar la lista de clientes
            handleCloseAccountDialog();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un error al activar la cuenta corriente.',
            });
            console.error('Error al activar la cuenta corriente:', error);
        }
    };

    const renderAccountStatus = (rowData) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {rowData.cuenta_corriente ? (
                    <span>Habilitada</span>
                ) : (
                    <>
                        <span>Deshabilitada</span>
                        <Button
                            icon="pi pi-plus"
                            className="p-button-rounded p-button-success"
                            onClick={() => openAccountDialog(rowData)} // Abre el modal de activación
                            tooltip="Activar Cuenta Corriente"
                            tooltipOptions={{ position: 'top' }}
                        />
                    </>
                )}
            </div>
        );
    };

    const openClientDialog = (client = null) => {
        setSelectedClient(client);
        setShowClientDialog(true);
    };

    const handleCloseClientDialog = () => {
        setShowClientDialog(false);
        setSelectedClient(null);
    };

    const renderActions = (rowData) => {
        return (
            <Button
                icon="pi pi-pencil"
                className="p-button-rounded p-button-info"
                onClick={() => openClientDialog(rowData)}
                tooltip="Editar Cliente"
                tooltipOptions={{ position: 'top' }}
            />
        );
    };

    const renderClientTypeIcon = (rowData) => {
        return rowData.empresa ? (
            <i className="pi pi-building" title="Empresa" style={{ fontSize: '1.2em', color: '#007ad9' }}></i>
        ) : (
            <i className="pi pi-user" title="Persona" style={{ fontSize: '1.2em', color: '#28a745' }}></i>
        );
    };

    return (
        <div style={{ margin: '0 auto', maxWidth: '90vw', paddingBottom: "1vw" }}>

            <div style={{ position: "relative", marginBlock: "10px 20px" }}>
                <h2 style={{ textAlign: "center", margin: 0 }}>Clientes</h2>

                <div style={{ position: "absolute", right: "1.5%", top: 0 }}>
                    <Button
                        icon="pi pi-plus"
                        label="Agregar Cliente"
                        className="p-button-rounded p-button-success"
                        onClick={() => openClientDialog()}
                    />
                </div>

            </div>


            {/* <h2 style={{ textAlign: 'center' }}>Clientes</h2> */}
            <div className="p-inputgroup" style={{ marginBottom: '20px' }}>
                <InputText
                    className="input-filter-clients"
                    placeholder="Buscar por CUIL"
                    value={cuilFilter}
                    onChange={(e) => setCuilFilter(e.target.value)}
                />
                <InputText
                    className="input-filter-clients"
                    placeholder="Buscar por Nombre o Razón Social"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                />
            </div>
            <DataTable value={filteredClients} paginator rows={10}>
                <Column body={renderClientTypeIcon} style={{ textAlign: 'center', width: '3rem' }} /> {/* Primera columna */}
                <Column field="cuil_dni" header="CUIL / CUIT" body={(rowData) => rowData.cuit || rowData.cuil_dni} />
                <Column field="nombre" header="Nombre / Razón Social" body={(rowData) => rowData.razon_social || `${rowData.nombre} ${rowData.apellido}`} />
                <Column field="telefono" header="Teléfono" />
                <Column field="domicilio" header="Domicilio" />
                {/* <Column field="cuenta_corriente" header="Cuenta Corriente" body={renderAccountStatus} /> */}
                <Column header="Acciones" body={renderActions} />
            </DataTable>

            {/* Botón para agregar un nuevo cliente */}
            {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: "12px" }}>
                <Button
                    icon="pi pi-plus"
                    label="Agregar Cliente"
                    className="p-button-rounded p-button-success"
                    onClick={() => openClientDialog()}
                />
            </div> */}

            {/* Modal para editar o crear cliente */}
            <Dialog
                style={{ width: '40vw' }}
                header={selectedClient ? "Editar Cliente" : "Agregar Cliente"}
                visible={showClientDialog}
                onHide={handleCloseClientDialog}
            >
                <FormClient
                    close={handleCloseClientDialog}
                    refreshClients={fetchClients}
                    selectedClient={selectedClient}
                />
            </Dialog>

            {/* Modal para activar cuenta corriente */}
            <Dialog
                style={{ width: '40vw' }}
                header="Activar Cuenta Corriente"
                visible={showAccountDialog}
                onHide={handleCloseAccountDialog}
            >
                <CcForm
                    client={selectedClient}
                    onSave={handleActivateAccount}
                    onCancel={handleCloseAccountDialog}
                />
            </Dialog>
        </div>
    );
};

export default Clients;
