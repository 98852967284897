import React from 'react';
import { formatMoney } from '../../helpers/generalhelpers';

const PeriodsCards = ({summaryData}) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            padding: '0.5vw 2vw',
        }}>
            {summaryData.map((item, index) => (
                <div key={index} style={{
                    borderRadius: '10px',
                    width: '15vw',
                    textAlign: 'center',
                    padding: '15px',
                    backgroundColor: item.color,
                    color: '#ffffff',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}>
                    <h4>{item.label}</h4>
                    <p style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        margin: '10px 0',
                    }}>
                        {formatMoney(item.value.toFixed(2)) }
                    </p>
                </div>
            ))}
        </div>
    );
};

export default PeriodsCards;