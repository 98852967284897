import Cookies from 'js-cookie';
import api from '../../helpers/axiosConfig'; // Asegúrate de que la ruta sea correcta

export const getOperatingExpensesLogs = async (params) => {
    try {
        // Obtener y validar el token del usuario
        const userdatastring = Cookies.get('user');
        if (!userdatastring) throw new Error('No se pudo obtener los datos del usuario.');

        const userdata = JSON.parse(userdatastring)?.user_data;
        const token = userdata?.token;

        if (!token) {
            throw new Error('Token no disponible. Inicie sesión nuevamente.');
        }

        // Realizar la solicitud GET con los parámetros recibidos
        const response = await api.get('/logs_gastos_operativos/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            params, // Parámetros opcionales: page, page_size, fecha_desde, fecha_hasta, responsable
        });

        // Verificar la respuesta
        if (response.status === 200) {
            return response.data; // Retorna los datos de la respuesta
        } else {
            throw new Error('Error inesperado al obtener los logs de gastos operativos.');
        }
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido.';
            console.error('Error de la API:', errorMessage);
            throw new Error(errorMessage);
        } else {
            console.error('Error inesperado:', error.message);
            throw new Error('Error inesperado al obtener los logs de gastos operativos.');
        }
    }
};
