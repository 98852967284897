// src/context/AuthContext.js

import { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { logoutUser } from '../components/login&auth/authServices';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        // Leer el usuario de las cookies al iniciar
        const userCookie = Cookies.get('user');
        return userCookie ? JSON.parse(userCookie) : null;
    });

    const login = (userData) => {
        setUser(userData);
        Cookies.set('user', JSON.stringify(userData), { expires: 0.5 });
    };

    const logout = async () => {
        try {
            const token = user?.user_data?.token || null;
            if (token) {
                const response = await logoutUser(token); // Llama a la función logoutUser
    
                if (response?.status !== "success") {
                    console.error("Error al cerrar sesión:", response);
                    alert("No se pudo cerrar sesión. Intenta nuevamente.");
                }
            }
        } catch (error) {
            console.error("Error al cerrar sesión:", error);
        } finally {
            // Asegurar que el usuario se borre sin importar el resultado
            setUser(null);
            Cookies.remove("user");
    
            setTimeout(() => {
                window.location.href = "/login";
            }, 1000);
        }
    };


    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
