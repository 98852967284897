import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2'; // Importamos SweetAlert2
import { useBudget } from '../../context/BudgetContext';
import { createBudget } from './budgetServices';
import { useGeneral } from '../../context/GeneralContext';

const BudgetOptions = () => {
    const { clearBudget, client, products, getTotal, openBudgetDetails, discount } = useBudget(); // Extraemos clearBudget y getTotal
    const { generalData } = useGeneral(); // Extraemos generalData

    // Manejo de la acción "Limpiar"
    const handleClear = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Se borrará el presupuesto actual.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, borrar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                clearBudget(); // Eliminar presupuesto
                Swal.fire({
                    icon: 'success',
                    title: 'Presupuesto borrado',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        });
    };

    const confirmBudget = async () => {
        const subtotal = getTotal(); // Obtener el total de los productos usando la función getTotal
        const total = subtotal - (subtotal * (discount / 100));
        const impuesto = parseFloat(((generalData.iva / 100) * total).toFixed(2));
    
        // Datos del presupuesto que se enviarán
        const budgetData = {
            cliente: client.id,
            impuesto, // Aplicamos el IVA calculado
            subtotal, 
            items_presupuesto: products.map(product => {
                const markupKey = `mark_up_${product.markup}`;
                return {
                    articulo: product.id,
                    cantidad: product.quantity,
                    precio_unitario: product.price,
                    total: product.total,
                    mark_up_porcentaje: parseInt(((product[markupKey] - 1) * 100).toFixed(2))
                }
            }),
            descuento: discount,
            total
        };
    
        try {
            const response = await createBudget(budgetData);
            console.log(response);
    
            Swal.fire({
                icon: 'success',
                title: 'Presupuesto confirmado',
                text: `Presupuesto generado con éxito.`,
                showCancelButton: true,
                confirmButtonText: 'Ver presupuesto',
                cancelButtonText: 'Cerrar',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
            }).then((result) => {
                if (result.isConfirmed) {
                    openBudgetDetails(response.presupuesto_id);
                }
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'No se pudo generar el presupuesto, intenta nuevamente.',
                confirmButtonColor: '#3085d6',
            });
        }
    };
    

    // Manejo de la acción "Confirmar"
    const handleConfirm = async () => {
        if (!client || products.length === 0) {
            // Verifica si el cliente o productos están vacíos
            Swal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Debes agregar productos y seleccionar un cliente para confirmar el presupuesto.',
                confirmButtonColor: '#3085d6',
            });
            return;
        }

        Swal.fire({
            title: '¿Estás seguro?',
            text: "Se generará el presupuesto actual.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                confirmBudget(); // Llamar a la función para confirmar el presupuesto
            }
        });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: "70vw", margin: '15px 0' }}>
                <Button
                    style={{ width: "150px" }}
                    label="Limpiar"
                    icon="pi pi-trash"
                    className="p-button-secondary p-button-rounded"
                    onClick={handleClear}
                />

                <Button
                    style={{ width: "150px" }}
                    label="Confirmar"
                    icon="pi pi-check"
                    className="p-button-primary p-button-rounded"
                    onClick={handleConfirm}
                />
            </div>
        </div>
    );
};

export default BudgetOptions;
