import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import DiscrepancyForm from './DiscrepancyForm';
import Swal from 'sweetalert2';
import { validateClosure } from '../treasuryServices';

const ClosureOptions = ({ fetchClosureDetails, id, closureDetails, discrepancies, allPaymentMethods }) => {
    const [showDiscrepancyDialog, setShowDiscrepancyDialog] = useState(false); // Modal for discrepancy

    // Filtrar los métodos de pago que no tienen discrepancias
    const availablePaymentMethods = allPaymentMethods.filter(
        (method) => !discrepancies.some((discrepancy) => discrepancy.metodo_pago_nombre === method.name)
    );

    const handleAddDiscrepancy = () => {
        setShowDiscrepancyDialog(true); // Open discrepancy modal
    };

    const handleCancelDiscrepancy = () => {
        setShowDiscrepancyDialog(false); // Close discrepancy modal
    };

    const handleValidate = async () => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¿Deseas validar este cierre de caja?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, validar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                const response = await validateClosure(id);
                if (response.system_status === 'success') {
                    Swal.fire('Validado', response.system_message, 'success');
                    fetchClosureDetails(); // Actualizar los detalles después de validar
                } else {
                    throw new Error(response.system_message);
                }
            } catch (error) {
                Swal.fire('Error', error.message || 'Ocurrió un error al validar el cierre de caja', 'error');
            }
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: "70vw", margin: '15px 0' }}>
                <Button
                    disabled={availablePaymentMethods?.length === 0}
                    style={{ width: "260px" }}
                    label="Agregar Discrepancia"
                    icon="pi pi-exclamation-triangle"
                    className="p-button-warning p-button-rounded"
                    onClick={handleAddDiscrepancy}
                />

                <Button
                    style={{ width: "200px" }}
                    label="Validar"
                    icon="pi pi-check"
                    className="p-button-success p-button-rounded"
                    onClick={handleValidate}
                />
            </div>

            {/* Modal for Adding Discrepancy */}
            <Dialog
                visible={showDiscrepancyDialog}
                onHide={handleCancelDiscrepancy}
                header="Agregar Discrepancia"
                style={{ width: '400px' }}
            >
                <DiscrepancyForm
                    fetchClosureDetails={fetchClosureDetails}
                    handleCancelDiscrepancy={handleCancelDiscrepancy}
                    id={id}
                    closureDetails={closureDetails}
                    availablePaymentMethods={availablePaymentMethods} // Pasar los métodos de pago disponibles
                />
            </Dialog>
        </div>
    );
};

export default ClosureOptions;
