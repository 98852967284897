import React, { useState } from 'react';
import { useBudget } from '../../context/BudgetContext';
import { formatMoney } from '../../helpers/generalhelpers';
import { useAuth } from '../../context/AuthContext';
import { hasRole } from '../../helpers/usersHelper';

const BudgetTotal = () => {
    const { getTotal, applyDiscount } = useBudget();
    const { user } = useAuth();

    const [applyDiscountCheckbox, setApplyDiscountCheckbox] = useState(false);
    const [discount, setDiscount] = useState("");
    const [discountError, setDiscountError] = useState(false);

    const total = getTotal() || 0;
    const maxDiscount = hasRole(["ADMIN"]) ? 100 : 10;
    const discountAmount = total * (discount / 100);
    const discountedTotal = total - discountAmount;

    const handleDiscountChange = (e) => {
        const value = e.target.value;

        if (value === "") {
            setDiscount("");
            setDiscountError(false);
            return;
        }

        const parsedValue = parseFloat(value);

        if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > maxDiscount) {
            setDiscountError(true);
        } else {
            setDiscountError(false);
            setDiscount(parsedValue);
            applyDiscount(parsedValue);
        }
    };

    const handleBlur = () => {
        if (applyDiscountCheckbox && discount === "") {
            setDiscountError(true);
        }
    };

    return (
        <div style={{
            margin: '20px auto',
            maxWidth: '90vw',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            fontSize: '20px',
            fontWeight: 'bold',
            backgroundColor: '#f4f4f4',
            padding: '10px 20px',
            borderRadius: '5px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid #ccc'
        }}>
            {/* 🔹 Sección izquierda - Checkbox y input de descuento */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="checkbox"
                        checked={applyDiscountCheckbox}
                        onChange={(e) => {
                            setApplyDiscountCheckbox(e.target.checked);
                            if (!e.target.checked) {
                                setDiscount(0);
                                applyDiscount(0);
                                setDiscountError(false);
                            }
                        }}
                    />
                    <label style={{ marginLeft: '10px', cursor: 'pointer' }}>Aplicar Descuento</label>
                </div>

                {applyDiscountCheckbox && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="discount" style={{ marginRight: '10px' }}>Descuento (%):</label>
                        <input
                            id="discount"
                            type="number"
                            value={discount === "" ? "" : discount}
                            onChange={handleDiscountChange}
                            onBlur={handleBlur} // 🔹 Valida si el campo queda vacío
                            style={{
                                width: '100px',
                                padding: '5px',
                                border: discountError ? '1px solid red' : '1px solid #ccc',
                                borderRadius: '5px'
                            }}
                            min={0}
                            max={maxDiscount}
                        />
                        {discountError && (
                            <small style={{ color: 'red', marginLeft: '10px' }}>
                                Ingrese un valor entre 0 y {maxDiscount}.
                            </small>
                        )}
                    </div>
                )}
            </div>

            {/* 🔹 Sección derecha - Totales alineados a la derecha */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span>Total:</span>
                    <span style={{ color: applyDiscountCheckbox? 'black' : "#2e8b57", marginLeft: '10px' }}>{formatMoney(total)}</span>
                </div>

                {applyDiscountCheckbox && (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <span>Descuento:</span>
                            <span style={{ color: '#d9534f', marginLeft: '10px' }}>- {formatMoney(discountAmount)}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <span>Total con Descuento:</span>
                            <span style={{ color: '#2e8b57', marginLeft: '10px' }}>{formatMoney(discountedTotal)}</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BudgetTotal;
