import Swal from 'sweetalert2';
import api from '../../helpers/axiosConfig';
import Cookies from 'js-cookie';

// Función para obtener registros contables
export const getTransactions = async ({
    paymentMethods = [],
    page = 1,
    pageSize = 10,
    fechaDesde = null,
    fechaHasta = null,
    usuario = null,
    tipoMovimiento = null
} = {}) => {

    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Construir parámetros de consulta
        const params = {
            metodo_pago: paymentMethods.length > 0 ? paymentMethods.join(',') : undefined,
            page,
            page_size: pageSize,
            fecha_desde: fechaDesde || undefined,
            fecha_hasta: fechaHasta || undefined,
            usuario: usuario || undefined,
            tipo_movimiento: tipoMovimiento || undefined,
        };

        // Realizar la solicitud GET a la API para obtener los registros
        const response = await api.get('/registro_contable/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            params, // Pasar los parámetros de la URL
        });

        // Verificar y devolver los datos de la respuesta
        if (response.status === 200) {
            return response.data; // Regresar datos de la API
        } else {
            throw new Error('Error inesperado al obtener los registros.');
        }
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido';
            console.error('Error en la solicitud:', errorMessage);
        } else {
            console.error('Error inesperado:', error.message);
        }
        throw error; // Lanza el error para manejarlo donde se llame
    }
};


// Función para registrar una extracción de caja
export const registerExtraction = async (extractionData) => {
    console.log(extractionData);

    try {
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Realizar la solicitud POST para registrar la extracción
        const response = await api.post('/registro_contable/retiro_caja/', extractionData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        console.log(response);


        // Mostrar alerta de éxito
        Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: response.data.system_message,
            confirmButtonColor: '#3085d6',
        });

        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido';

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
                confirmButtonColor: '#d33',
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ha ocurrido un error inesperado',
                confirmButtonColor: '#d33',
            });
        }

        throw error; // Lanza el error para manejarlo en el componente
    }
};


// Función para obtener el resumen del periodo contable actual
export const getCurrentPeriodSummary = async () => {
    try {
        // Obtener el token del usuario
        const userdatastring = Cookies.get('user');
        const userdata = JSON.parse(userdatastring).user_data;
        const token = userdata.token;

        if (!token) {
            throw new Error('Token no disponible');
        }

        // Realizar la solicitud GET al endpoint del resumen del período contable actual
        const response = await api.get('/registro_contable/resumen_periodo_actual/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        // Verificar la respuesta y devolver los datos
        if (response.status === 200) {
            return response.data; // Retornar los datos de la API
        } else {
            throw new Error('Error inesperado al obtener el resumen del periodo actual.');
        }
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido';
            console.error('Error en la solicitud:', errorMessage);
        } else {
            console.error('Error inesperado:', error.message);
        }
        throw error; // Lanza el error para manejarlo donde se llame
    }
};

// Función para cerrar el periodo de transacciones
export const closePeriod = async () => {
    try {
        // Obtener y validar el token del usuario
        const userdatastring = Cookies.get('user');
        if (!userdatastring) throw new Error('No se pudo obtener los datos del usuario.');

        const userdata = JSON.parse(userdatastring)?.user_data;
        const token = userdata?.token;

        if (!token) {
            throw new Error('Token no disponible. Inicie sesión nuevamente.');
        }

        // Realizar la solicitud POST
        const response = await api.post('/registro_contable/finalizar_periodo/', {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        console.log('====================================');
        console.log(response);
        console.log('====================================');
        // Manejar respuesta exitosa
        return response.data; // Retorna la respuesta del backend
    } catch (error) {
        // Manejar errores
        if (error.response && error.response.data) {
            console.log(error.response);

            const errorMessage = error.response.data.system_message || 'Error desconocido.';
            console.error('Error de la API:', errorMessage);
            throw new Error(errorMessage); // Lanza el mensaje del backend
        } else {
            console.error('Error inesperado:', error.message);
            throw new Error('Error inesperado al cerrar el periodo.');
        }
    }
};

// Función para editar datos relacionados a una extracción
export const editExtraction = async (id, montoTotal, motivo) => {
    try {
        // Obtener y validar el token del usuario
        const userdatastring = Cookies.get('user');
        if (!userdatastring) throw new Error('No se pudo obtener los datos del usuario.');

        const userdata = JSON.parse(userdatastring)?.user_data;
        const token = userdata?.token;

        if (!token) {
            throw new Error('Token no disponible. Inicie sesión nuevamente.');
        }

        // Construir el cuerpo de la solicitud
        const body = {
            monto_total: montoTotal,
            motivo
        };

        // Realizar la solicitud PATCH
        const response = await api.patch(`/registro_contable/${id}/`, body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        // Manejar respuesta exitosa
        if (response.status === 206) {
            Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'La extracción fue editada correctamente.',
                confirmButtonColor: '#3085d6',
            });
            return response.data; // Retorna los datos de la API
        } else {
            throw new Error('Error inesperado al editar la extracción.');
        }
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido.';
            console.error('Error de la API:', errorMessage);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
                confirmButtonColor: '#d33',
            });
        } else {
            console.error('Error inesperado:', error.message);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error inesperado al editar la extracción.',
                confirmButtonColor: '#d33',
            });
        }

        throw error; // Lanza el error para manejarlo donde se llame
    }
};

// Función para eliminar un registro
export const deleteRegistroContable = async (id) => {
    try {
        // Obtener y validar el token del usuario
        const userdatastring = Cookies.get('user');
        if (!userdatastring) throw new Error('No se pudo obtener los datos del usuario.');

        const userdata = JSON.parse(userdatastring)?.user_data;
        const token = userdata?.token;

        if (!token) {
            throw new Error('Token no disponible. Inicie sesión nuevamente.');
        }

        // Realizar la solicitud DELETE
        const response = await api.delete(`/registro_contable/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        // Manejar respuesta exitosa
        if (response.status === 204) {
            return {
                system_status: 'success',
                system_message: 'Registro eliminado exitosamente!',
            };
        } else {
            throw new Error('Error inesperado al eliminar el registro.');
        }
    } catch (error) {
        // Manejo de errores
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.system_message || 'Error desconocido.';
            console.error('Error de la API:', errorMessage);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
                confirmButtonColor: '#d33',
            });
        } else {
            console.error('Error inesperado:', error.message);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error inesperado al eliminar el registro.',
                confirmButtonColor: '#d33',
            });
        }

        throw error; // Lanza el error para manejarlo en el componente
    }
};