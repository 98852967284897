import React from 'react';
import { Badge } from 'primereact/badge';

const CashClosureHeader = ({ closureDetails }) => {
    const { id, created_at, estado } = closureDetails;

    // Formatear la fecha
    const formattedDate = new Date(created_at).toLocaleString('es-AR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });

    // Colores para el badge según el estado
    const getEstadoStyle = (estado) => {
        switch (estado) {
            case 'PENDIENTE':
                return { backgroundColor: '#f8d7da', color: '#a93226', fontWeight: 'bold' };
            case 'VALIDADO':
                return { backgroundColor: '#d4edda', color: '#229954', fontWeight: 'bold' };
            case 'VALIDADO CON DISCREPANCIAS':
                return { backgroundColor: '#d1ecf1', color: '#2980b9', fontWeight: 'bold' };
            default:
                return { backgroundColor: '#e2e3e5', color: '#383d41', fontWeight: 'bold' };
        }
    };

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto 1fr', // Tres columnas: espacio a la izquierda, título centrado, datos a la derecha
            alignItems: 'center',
            padding: '10px 20px',
            backgroundColor: '#f9f9f9',
        }}>
            {/* Título centrado */}
            <div style={{
                gridColumn: '2 / 3', // Centrar el título en la segunda columna
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '1.5rem',
            }}>
                {`Cierre de Caja ${id}`}
            </div>

            {/* Datos a la derecha */}
            <div style={{
                gridColumn: '3 / 4', // Colocar los datos en la tercera columna
                textAlign: 'right',
                whiteSpace: 'nowrap', // Evitar que se rompa el texto
            }}>
                <div>
                    <strong>Estado:</strong>
                    <Badge
                        value={estado}
                        style={{
                            ...getEstadoStyle(estado),
                            padding: '0px 5px',
                            borderRadius: '8px',
                            fontSize: '0.9rem',
                            marginLeft: "2px"
                        }}
                    />
                </div>
                <div style={{ marginTop: '5px' }}>
                    <strong>Fecha de cierre:</strong> {formattedDate}
                </div>
            </div>
        </div>
    );
};

export default CashClosureHeader;
