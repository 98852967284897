import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2';
import { deleteOperatingExpense } from '../treasuryServices';

const OperatingExpensesList = ({ expenses, onEdit, refreshList, totalRecords, setPage, page }) => {
    const handleDelete = async (rowData) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará el gasto operativo de forma permanente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteOperatingExpense(rowData.id);
                    Swal.fire('Eliminado', 'El gasto operativo fue eliminado correctamente.', 'success');
                } catch (error) {
                    console.error('Error al eliminar el gasto operativo:', error);
                    Swal.fire('Error', 'No se pudo eliminar el gasto operativo. Intente nuevamente.', 'error');
                }
            }
            refreshList();
        });
    };

    const handlePageChange = (event) => {
        const newPage = event.page + 1; // PrimeReact usa 0-indexed, pero el backend usa 1-indexed
        setPage(newPage);
    };

    return (
        <div style={{ maxHeight: 'auto', overflowY: 'auto' }}> {/* Contenedor para asegurar límite */}
            <DataTable
                value={expenses}
                paginator
                rows={10}
                first={(page - 1) * 10}
                totalRecords={totalRecords}
                lazy
                onPage={handlePageChange}
                emptyMessage="No hay gastos operativos disponibles."
                scrollable   // Habilita el scroll
                scrollHeight="60vh"  // Define altura máxima antes de activar el scroll
            >
                <Column
                    field="id"
                    header="ID"
                    body={(rowData) => (
                        <span style={{ fontWeight: 'bold', color: '#21538a' }}>
                            {rowData.id}
                        </span>
                    )}
                ></Column>

                <Column 
                    field="responsable_usuario" 
                    header="Responsable" 
                    body={(rowData) => (
                        <span>
                            <i className="pi pi-user" style={{ marginRight: '5px', color: '#21538a' }}></i>
                            {rowData.responsable_usuario}
                        </span>
                    )} 
                    style={{ width: '15%' }}
                ></Column>

                <Column
                    style={{ width: '20%' }}
                    field="metodo_pago_nombre"
                    header="Método de Pago"
                    body={(rowData) => {
                        let iconClass = '';
                        switch (rowData.metodo_pago) {
                            case 'Efectivo':
                                iconClass = 'pi pi-wallet';
                                break;
                            case 'Transferencia':
                                iconClass = 'pi pi-download';
                                break;
                            case 'Tarjeta de Credito':
                                iconClass = 'pi pi-credit-card';
                                break;
                            default:
                                iconClass = 'pi pi-question';
                        }
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <i className={iconClass} style={{ fontSize: '1.2rem' }}></i>
                                {rowData.metodo_pago}
                            </div>
                        );
                    }}
                ></Column>

                <Column
                    field="monto_total"
                    header="Monto Total"
                    body={(rowData) => `$${rowData.monto_total.toFixed(2)}`}
                    style={{ width: '15%' }}
                ></Column>

                <Column field="motivo" header="Motivo" style={{ width: '35%' }}></Column>

                <Column
                    field="fecha_hora"
                    header="Fecha y Hora"
                    body={(rowData) => 
                        new Date(rowData.fecha_hora).toLocaleString('es-AR', {
                            hour12: false,
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })
                    }
                    style={{ width: '25%' }}
                ></Column>

                <Column field="nro_comprobante" header="Nro Comprobante"></Column>

                <Column
                    header="Acciones"
                    body={(rowData) => (
                        <div style={{ display: 'flex', gap: '0.5rem' }}>
                            <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-text"
                                onClick={() => onEdit(rowData)}
                                title="Editar"
                            />
                            <Button
                                icon="pi pi-trash"
                                className="p-button-rounded p-button-danger p-button-text"
                                onClick={() => handleDelete(rowData)}
                                title="Eliminar"
                            />
                        </div>
                    )}
                    style={{ width: '10%' }}
                ></Column>
            </DataTable>
        </div>
    );
};

export default OperatingExpensesList;
