import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getClosures } from './treasuryServices';
import { formatMoney } from '../../helpers/generalhelpers';
import { Button } from 'primereact/button';
import { getUsers } from '../users/usersServices';

const CashClosuresList = () => {
    const [closures, setClosures] = useState([]); // Estado para almacenar los cierres de caja
    const [loading, setLoading] = useState(true); // Estado de carga
    const [totalRecords, setTotalRecords] = useState(0); // Total de registros
    const [page, setPage] = useState(1); // Página actual
    const [pageSize, setPageSize] = useState(10); // Tamaño de página
    const [filters, setFilters] = useState({
        fechaDesde: '',
        fechaHasta: '',
        responsableCaja: '',
        responsableTesoreria: '',
    });

    const [cajaUsers, setCajaUsers] = useState([]); // Estado para almacenar los usuarios
    const [tesoreriaUsers, setTesoreriaUsers] = useState([]); // Estado para almacenar los usuarios

    const fetchUsers = async () => {
        try {
            const data = await getUsers(); // Llama a la función para obtener usuarios

            // Filtrar usuarios que tengan el grupo CAJA
            const usersCaja = data.registros.filter((user) =>
                user.grupos.some((group) => group.name === 'CAJA')
            );

            // Filtrar usuarios que tengan el grupo TESORERIA
            const usersTesoreria = data.registros.filter((user) =>
                user.grupos.some((group) => group.name === 'TESORERO')
            );

            setCajaUsers(usersCaja); // Guarda los usuarios filtrados en el estado
            setTesoreriaUsers(usersTesoreria); // Guarda los usuarios filtrados en el estado
        } catch (error) {
            console.error('Error al obtener usuarios:', error);
        }
    };

    // Función para cargar los cierres de caja
    const fetchClosures = async () => {
        setLoading(true);
        try {
            const data = await getClosures({ page, pageSize, ...filters });
            setClosures(data.results);
            setTotalRecords(data.total_registros);
        } catch (error) {
            console.error('Error al obtener los cierres de caja:', error);
        } finally {
            setLoading(false);
        }
    };

    // Llama a `fetchClosures` automáticamente al cambiar filtros, página o tamaño de página
    useEffect(() => {
        fetchClosures();
    }, [filters, page, pageSize]);

    useEffect(() => {
        fetchUsers();
    }, []);
    useEffect(() => {
        console.log(closures);
    }, [closures]);

    // Limpiar filtros
    const clearFilters = () => {
        setFilters({
            fechaDesde: '',
            fechaHasta: '',
            responsableCaja: '',
            responsableTesoreria: '',
        });
        setPage(1);
    };

    // Función para abrir los datos del cierre de caja
    const openClosureDetails = (id) => {
        const url = `/cash-closure/${id}`;
        window.open(url, '_blank');
    };

    return (
        <div >
            <h2 style={{ marginLeft: "10px" }}>Cierres de Caja</h2>
            {/* Filtros de búsqueda */}
            <div className="form-container-transactions" style={{ marginBottom: '20px' }}>
                <div className="form-group-transactions">
                    <label className="form-label-transactions" htmlFor="fecha-desde">
                        Fecha Desde
                    </label>
                    <input
                        id="fecha-desde"
                        type="date"
                        className="input-field-transactions"
                        value={filters.fechaDesde}
                        onChange={(e) =>
                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                fechaDesde: e.target.value,
                            }))
                        }
                    />
                </div>

                <div className="form-group-transactions">
                    <label className="form-label-transactions" htmlFor="fecha-hasta">
                        Fecha Hasta
                    </label>
                    <input
                        id="fecha-hasta"
                        type="date"
                        className="input-field-transactions"
                        value={filters.fechaHasta}
                        onChange={(e) =>
                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                fechaHasta: e.target.value,
                            }))
                        }
                    />
                </div>

                <div className="form-group-transactions">
                    <label className="form-label-transactions" htmlFor="responsable-caja">
                        Responsable Caja
                    </label>
                    <select
                        id="responsable-caja"
                        className="input-field-transactions"
                        value={filters.responsableCaja} // Valor actual seleccionado
                        onChange={(e) =>
                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                responsableCaja: e.target.value,
                            }))
                        }
                    >
                        <option value="">- TODOS -</option>
                        {cajaUsers.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.username}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group-transactions">
                    <label className="form-label-transactions" htmlFor="responsable-tesoreria">
                        Responsable Tesorería
                    </label>
                    <select
                        id="responsable-tesoreria"
                        className="input-field-transactions"
                        value={filters.responsableTesoreria} // Valor actual seleccionado
                        onChange={(e) =>
                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                responsableTesoreria: e.target.value,
                            }))
                        }
                    >
                        <option value="">- TODOS -</option>
                        {tesoreriaUsers.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.username}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Contenedor para el botón alineado a la derecha */}
                <div style={{ marginTop: '20px' }}>
                    <Button
                        label="Limpiar Filtros"
                        icon="pi pi-filter-slash"
                        className="p-button-secondary"
                        onClick={clearFilters}
                    />
                </div>
            </div>


            {/* Tabla de cierres de caja */}
            <DataTable
                value={closures}
                loading={loading}
                paginator
                rows={pageSize}
                first={(page - 1) * pageSize}
                totalRecords={totalRecords}
                lazy
                onPage={(e) => {
                    setPage(e.page + 1);
                    setPageSize(e.rows);
                }}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                emptyMessage="No se encontraron cierres de caja."
                style={{ width: '100%' }}
                scrollable   // Habilita el scroll
                scrollHeight="60vh"  // Define altura máxima antes de activar el scroll
            >
                <Column
                    field="id"
                    header="ID"
                    body={(rowData) => (
                        <span style={{ fontWeight: 'bold', color: '#21538a' /* Cambia este color */ }}>
                            {rowData.id}
                        </span>
                    )}
                    style={{ width: '10%' }}
                ></Column>

                {/* Columna Responsable con icono de usuario */}
                <Column
                    field="responsable_caja_usuario"
                    header="Responsable Caja"
                    body={(rowData) => (
                        <span>
                            <i className="pi pi-user" style={{ marginRight: '5px', color: '#21538a' }}></i>
                            {rowData.responsable_caja_usuario}
                        </span>
                    )}
                    style={{ width: '15%' }}
                ></Column>



                <Column
                    field="responsable_tesoreria_usuario"
                    header="Responsable Tesorería"
                    body={(rowData) =>
                        rowData.responsable_tesoreria_usuario ? (rowData) => (
                            <span>
                                <i className="pi pi-user" style={{ marginRight: '5px', color: '#d35400' }}></i>
                                {rowData.responsable_tesoreria_usuario}
                            </span>
                        ) : (
                            <span style={{ color: 'gray', fontStyle: 'italic' }}>Pendiente</span>
                        )
                    }
                    style={{ width: '20%' }}
                ></Column>
                <Column
                    field="estado"
                    header="Estado"
                    body={(rowData) => {
                        let style = {};
                        let label = rowData.estado;

                        switch (rowData.estado) {
                            case 'PENDIENTE':
                                style = { color: '#a93226', fontWeight: 'bold' };
                                break;
                            case 'VALIDADO':
                                style = { color: '#229954', fontWeight: 'bold' };
                                break;
                            case 'VALIDADO CON DISCREPANCIAS':
                                style = { color: '#2980b9', fontWeight: 'bold' };
                                break;
                            default:
                                style = { color: '#383d41', fontWeight: 'bold' };
                        }

                        return <span style={style}>{label}</span>;
                    }}
                    style={{ width: '15%' }}
                ></Column>
                <Column
                    field="efectivo"
                    header="Efectivo"
                    body={(rowData) => formatMoney(rowData.efectivo)}
                    style={{ width: '15%' }}
                ></Column>
                <Column
                    field="transferencia"
                    header="Transferencia"
                    body={(rowData) => formatMoney(rowData.transferencia)}
                    style={{ width: '15%' }}
                ></Column>
                <Column
                    field="tarjeta_credito"
                    header="Tarjeta de Crédito"
                    body={(rowData) => formatMoney(rowData.tarjeta_credito)}
                    style={{ width: '15%' }}
                ></Column>
                <Column
                    field="discrepancias"
                    header="Discrepancias"
                    body={(rowData) => formatMoney(rowData.discrepancias)}
                    style={{ width: '15%' }}
                ></Column>
                <Column
                    field="reserva"
                    header="Reserva"
                    body={(rowData) => formatMoney(rowData.reserva)}
                    style={{ width: '15%', textAlign: 'right' }}
                ></Column>
                <Column
                    field="created_at"
                    header="Fecha"
                    body={(rowData) => new Date(rowData.created_at).toLocaleString()}
                    style={{ width: '20%' }}
                ></Column>
                <Column
                    header="Detalles"
                    body={(rowData) => (
                        <Button
                            icon="pi pi-file"
                            className="p-button-text p-button-info"
                            onClick={() => openClosureDetails(rowData.id)}
                        />
                    )}
                    style={{ width: '10%', textAlign: 'center' }}
                ></Column>
            </DataTable>
        </div>
    );
};

export default CashClosuresList;
