import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import Swal from 'sweetalert2';
import { createOperatingExpense, editOperatingExpense } from '../treasuryServices';

const FormOperatingExpenses = ({ handleClose, refreshList, previousData }) => {
    const [amount, setAmount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [reason, setReason] = useState('');
    const [customReason, setCustomReason] = useState('');
    const [receiptNumber, setReceiptNumber] = useState('');
    const [date, setDate] = useState(new Date());
    const [errors, setErrors] = useState({});

    const paymentMethods = [
        { label: 'Efectivo', value: 'Efectivo' },
        { label: 'Transferencia', value: 'Transferencia' },
        { label: 'Tarjeta de Crédito', value: 'Tarjeta de Credito' },
    ];

    const reasons = [
        { label: 'Mantenimiento', value: 'Mantenimiento' },
        { label: 'Insumos', value: 'Insumos' },
        { label: 'Servicios', value: 'Servicios' },
        { label: 'Otro', value: 'Otro' },
    ];

    useEffect(() => {
        if (previousData) {
            setAmount(previousData.monto_total?.toString() || '');
            setPaymentMethod(previousData.metodo_pago || '');

            // Verificar si el motivo es preestablecido o personalizado
            if (reasons.some((option) => option.value === previousData.motivo)) {
                setReason(previousData.motivo); // Motivo predefinido
                setCustomReason(''); // No hay motivo personalizado
            } else {
                setReason('Otro'); // Seleccionar "Otro"
                setCustomReason(previousData.motivo || ''); // Asignar el motivo personalizado
            }

            setReceiptNumber(previousData.nro_comprobante || '');
            setDate(previousData.fecha_hora ? new Date(previousData.fecha_hora) : new Date());
        }
    }, [previousData]);

    const validateFields = () => {
        const newErrors = {};
        if (!amount || isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
            newErrors.amount = 'El monto es obligatorio y debe ser un número mayor a 0.';
        }
        if (!paymentMethod) {
            newErrors.paymentMethod = 'El método de pago es obligatorio.';
        }
        if (!reason) {
            newErrors.reason = 'El motivo es obligatorio.';
        }
        if (reason === 'Otro' && !customReason) {
            newErrors.customReason = 'Por favor especifique el motivo.';
        }
        if (!date && !previousData) {
            newErrors.date = 'La fecha y hora son obligatorias.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateFields()) return;

        try {
            let data = {
                monto_total: parseFloat(amount),
                metodo_pago: paymentMethod,
                motivo: reason === 'Otro' ? customReason : reason,
                nro_comprobante: receiptNumber || undefined,
            };

            if (!previousData) {
                const offset = date.getTimezoneOffset();
                const localISO = new Date(date.getTime() - offset * 60000).toISOString().slice(0, -1);
                data = { ...data, fecha_hora: localISO };
            }

            if (previousData) {
                await editOperatingExpense(previousData.id, data);
                Swal.fire('Éxito', 'El gasto operativo fue actualizado correctamente.', 'success');
            } else {
                await createOperatingExpense(data);
                Swal.fire('Éxito', 'El gasto operativo fue registrado correctamente.', 'success');
            }

            handleClose();
            refreshList();
        } catch (error) {
            console.error('Error al registrar o editar el gasto operativo:', error);
            Swal.fire('Error', 'No se pudo registrar o editar el gasto operativo. Intente nuevamente.', 'error');
        }
    };

    return (
        <div style={{
            borderRadius: '8px',
            maxWidth: '500px',
            margin: '0 auto',
            fontFamily: 'Arial, sans-serif',
        }}>
            <div className="field" style={{ marginBottom: '15px' }}>
                <label htmlFor="amount">Monto:</label>
                <InputText
                    id="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Ingrese el monto"
                    style={{
                        width: '100%',
                        borderColor: errors.amount ? 'red' : '#ccc',
                    }}
                    className={errors.amount ? 'p-invalid' : ''}
                />
                {errors.amount && <small className="p-error">{errors.amount}</small>}
            </div>

            <div className="field" style={{ marginBottom: '15px' }}>
                <label htmlFor="paymentMethod">Método de Pago:</label>
                <Dropdown
                    id="paymentMethod"
                    value={paymentMethod}
                    options={paymentMethods}
                    onChange={(e) => setPaymentMethod(e.value)}
                    placeholder="Seleccione un método de pago"
                    style={{ width: '100%' }}
                    className={errors.paymentMethod ? 'p-invalid' : ''}
                />
                {errors.paymentMethod && <small className="p-error">{errors.paymentMethod}</small>}
            </div>

            <div className="field" style={{ marginBottom: '15px' }}>
                <label htmlFor="reason">Motivo:</label>
                <Dropdown
                    id="reason"
                    value={reason}
                    options={reasons}
                    onChange={(e) => setReason(e.value)}
                    placeholder="Seleccione un motivo"
                    style={{ width: '100%' }}
                    className={errors.reason ? 'p-invalid' : ''}
                />
                {errors.reason && <small className="p-error">{errors.reason}</small>}
            </div>

            {reason === 'Otro' && (
                <div className="field" style={{ marginBottom: '15px' }}>
                    <label htmlFor="customReason">Especifique el motivo:</label>
                    <InputText
                        id="customReason"
                        value={customReason}
                        onChange={(e) => setCustomReason(e.target.value)}
                        placeholder="Escriba el motivo"
                        style={{
                            width: '100%',
                            borderColor: errors.customReason ? 'red' : '#ccc',
                        }}
                        className={errors.customReason ? 'p-invalid' : ''}
                    />
                    {errors.customReason && <small className="p-error">{errors.customReason}</small>}
                </div>
            )}

            <div className="field" style={{ marginBottom: '15px' }}>
                <label htmlFor="date">Fecha y Hora:</label>
                <Calendar
                    id="date"
                    value={date}
                    onChange={(e) => setDate(e.value)}
                    showTime
                    hourFormat="24"
                    placeholder="Seleccione fecha y hora"
                    style={{
                        width: '100%',
                        borderColor: errors.date ? 'red' : '#ccc',
                    }}
                    className={errors.date ? 'p-invalid' : ''}
                    disabled={!!previousData}
                />
                {errors.date && <small className="p-error">{errors.date}</small>}
            </div>

            <div className="field" style={{ marginBottom: '15px' }}>
                <label htmlFor="receiptNumber">Número de Comprobante (Opcional):</label>
                <InputText
                    id="receiptNumber"
                    value={receiptNumber}
                    onChange={(e) => setReceiptNumber(e.target.value)}
                    placeholder="Ingrese el número de comprobante"
                    style={{
                        width: '100%',
                        borderColor: '#ccc',
                    }}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    className="p-button-secondary"
                    onClick={handleClose}
                />
                <Button
                    label={previousData ? "Guardar Cambios" : "Registrar"}
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};

export default FormOperatingExpenses;
