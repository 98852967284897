import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown'; // Importar el componente Dropdown
import { editArticle, createArticle, deleteArticle } from './articlesServices';
import { getProviders } from '../providers/providersService'; // Asegúrate de importar la función
import { getPresentacionesArticulos } from './articlesServices'; // Importar la función para obtener las presentaciones
import Swal from 'sweetalert2'; // Importamos SweetAlert2
import { InputSwitch } from 'primereact/inputswitch';
import { formatMoney } from '../../helpers/generalhelpers';

const FormArticle = ({ selectedArticle, close, refreshArticles }) => {
    const [formData, setFormData] = useState({
        sku: '',
        descripcion: '',
        proveedor: '',
        presentacion: '',
        costo: '',
        cantidad_disponible: '',
        mark_up_1: "",
        mark_up_2: "",
        mark_up_3: "",
        iva: 21
    });

    const [providers, setProviders] = useState([]); // Estado para almacenar los proveedores
    const [presentations, setPresentations] = useState([]); // Estado para almacenar las presentaciones
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    // Obtener la lista de proveedores y presentaciones al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [providersRes, presentationsRes] = await Promise.all([
                    getProviders(), // Llamada para obtener los proveedores
                    getPresentacionesArticulos() // Llamada para obtener las presentaciones de artículos
                ]);
                console.log(providersRes);
                console.log(presentationsRes);

                setProviders(providersRes.registros); // Actualiza el estado con los proveedores
                setPresentations(presentationsRes.registros); // Actualiza el estado con las presentaciones
            } catch (error) {
                console.error('Error al obtener los proveedores o presentaciones:', error);
                setMessage('Error al cargar los proveedores o presentaciones');
            }
        };
        fetchData();
    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.sku) newErrors.sku = 'El SKU es obligatorio';
        if (!formData.descripcion) newErrors.descripcion = 'La descripción es obligatoria';
        if (!formData.proveedor) newErrors.proveedor = 'El proveedor es obligatorio';
        if (!formData.presentacion) newErrors.presentacion = 'La presentación es obligatoria';
        if (!formData.costo) newErrors.costo = 'El costo es obligatorio';
        if (!formData.cantidad_disponible) newErrors.cantidad_disponible = 'La cantidad es obligatoria';
        if (!formData.mark_up_1) newErrors.mark_up_1 = 'Mark up obligatorio';
        if (!formData.mark_up_2) newErrors.mark_up_2 = 'Mark up obligatorio';
        if (!formData.mark_up_3) newErrors.mark_up_3 = 'Mark up obligatorio';
        if (formData.mark_up_1 && formData.mark_up_1 <= 0) newErrors.mark_up_1 = 'Mark up debe ser mayor a 0';
        if (formData.mark_up_2 && formData.mark_up_2 <= 0) newErrors.mark_up_2 = 'Mark up debe ser mayor a 0';
        if (formData.mark_up_3 && formData.mark_up_3 <= 0) newErrors.mark_up_3 = 'Mark up debe ser mayor a 0';


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const onAccept = async () => {
        if (validateForm()) {
            console.log('Datos a enviar:', formData);
            try {
                if (selectedArticle) {
                    // Edición de artículo
                    // await editArticle(selectedArticle.id, formData).then((res) => console.log(res))
                    // Swal.fire({
                    //     icon: 'success',
                    //     title: 'Artículo editado',
                    //     text: 'El artículo se ha editado correctamente.',
                    //     confirmButtonColor: '#3085d6'
                    // });
                    await editArticle(selectedArticle.id, formData).then((res) => {
                        console.log(res);
                        if (res.status === 200) {
                            console.log('Artículo editado:', res.data);
                            Swal.fire({
                                icon: 'success',
                                title: 'Artículo editado',
                                text: 'El artículo se ha editado correctamente.',
                                confirmButtonColor: '#3085d6'
                            });
                        }
                        refreshArticles();
                        close();

                    });
                } else {
                    // Creación de artículo
                    await createArticle(formData).then((res) => {
                        if (res.status === 201) {
                            console.log('Artículo creado:', res.data);
                            Swal.fire({
                                icon: 'success',
                                title: 'Artículo creado',
                                text: 'El artículo se ha creado correctamente.',
                                confirmButtonColor: '#3085d6'
                            });
                        }
                        refreshArticles();
                        close();

                    });

                }
            } catch (error) {
                close();
                console.error('Error al procesar la acción:', error);
                setMessage(error.response.data.descripcion);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al crear o editar el artículo.',
                    confirmButtonColor: '#d33'
                });
            }
        }
    };

    const handleDelete = async () => {
        close();
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás recuperar este artículo después de eliminarlo.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                await deleteArticle(selectedArticle.id); // Llama a la función para eliminar el artículo
                setMessage('Artículo eliminado exitosamente');
                Swal.fire({
                    icon: 'success',
                    title: 'Artículo eliminado',
                    text: 'El artículo se ha eliminado correctamente.',
                    confirmButtonColor: '#3085d6'
                });
                refreshArticles(); // Refresca la lista de artículos después de eliminar
                close(); // Cierra el modal
            } catch (error) {
                console.error('Error al eliminar el artículo:', error);
                setMessage('Error al eliminar el artículo');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al eliminar el artículo.',
                    confirmButtonColor: '#d33'
                });
            }
        }
    };

    useEffect(() => {
        if (selectedArticle) {
            setFormData({
                sku: selectedArticle.sku || '',
                descripcion: selectedArticle.descripcion || '',
                proveedor: selectedArticle.proveedor_nombre || '',
                presentacion: selectedArticle.presentacion || '',
                costo: selectedArticle.costo || '',
                cantidad_disponible: selectedArticle.cantidad_disponible || '',
                mark_up_1: (selectedArticle.mark_up_1 - 1.00).toFixed(2) * 100 || '',
                mark_up_2: (selectedArticle.mark_up_2 - 1.00).toFixed(2) * 100 || '',
                mark_up_3: (selectedArticle.mark_up_3 - 1.00).toFixed(2) * 100 || '',
                iva: selectedArticle.iva || 21
            });
        }
    }, [selectedArticle]);

    return (
        <div>
            <div className="form-container">
                <div className="form-group">
                    <span className="form-label">SKU - Código</span>
                    <InputText
                        placeholder="SKU - Código"
                        className={`input-field input-field-providers ${selectedArticle ? 'disabled-input' : ''}`}
                        name="sku"
                        value={formData.sku}
                        onChange={handleInputChange}
                        disabled={!!selectedArticle}
                    />
                    {errors.sku && <small className="p-error">{errors.sku}</small>}
                </div>
                <div className="form-group">
                    <span className="form-label">Descripción</span>
                    <InputText
                        placeholder="Descripción"
                        className="input-field input-field-providers"
                        name="descripcion"
                        value={formData.descripcion}
                        onChange={handleInputChange}
                    />
                    {errors.descripcion && <small className="p-error">{errors.descripcion}</small>}
                </div>
                <div className="form-group">
                    <span className="form-label">Proveedor</span>
                    <Dropdown
                        placeholder="Seleccionar Proveedor"
                        value={formData.proveedor}
                        options={providers} // Lista de proveedores
                        onChange={(e) => setFormData({ ...formData, proveedor: e.value })}
                        optionLabel="nombre" // Campo visible para el usuario
                        className={`input-field input-field-providers ${formData.proveedor ? 'no-arrow' : ''
                            } ${selectedArticle ? 'disabled-input' : ''}`}
                        disabled={!!selectedArticle} // Deshabilitar cuando esté en modo de edición
                        filter // Habilitar búsqueda
                        filterBy="nombre" // Campo por el cual filtrar
                        showClear={!!formData.proveedor} // Mostrar "X" solo si hay un valor seleccionado
                    />
                    {errors.proveedor && <small className="p-error">{errors.proveedor}</small>}
                </div>

                <div className="form-group">
                    <span className="form-label">Presentación</span>
                    <Dropdown
                        placeholder="Seleccionar Presentación"
                        value={formData.presentacion}
                        options={presentations} // Lista de presentaciones
                        onChange={(e) => setFormData({ ...formData, presentacion: e.value })}
                        optionLabel="descripcion" // Campo visible para el usuario
                        className={`input-field input-field-providers ${formData.presentacion ? 'no-arrow' : ''
                            } ${selectedArticle ? 'disabled-input' : ''}`}
                        disabled={!!selectedArticle} // Deshabilitar cuando esté en modo de edición
                        filter // Habilitar búsqueda
                        filterBy="descripcion" // Campo por el cual filtrar
                        showClear={!!formData.presentacion} // Mostrar "X" solo si hay un valor seleccionado
                    />
                    {errors.presentacion && <small className="p-error">{errors.presentacion}</small>}
                </div>



                <div className="form-group">
                    <span className="form-label">Costo</span>
                    <InputText
                        placeholder="Costo"
                        className="input-field input-field-providers"
                        name="costo"
                        value={formData.costo}
                        onChange={(e) => {
                            const value = e.target.value;
                            // Permitir solo números y un punto decimal (regex valida decimales)
                            if (/^\d*\.?\d*$/.test(value)) {
                                setFormData({ ...formData, costo: value });
                            }
                        }}
                    />
                    {errors.costo && <small className="p-error">{errors.costo}</small>}

                </div>
                <div className="form-group">
                    <span className="form-label">Cantidad</span>
                    <InputText
                        placeholder="Cantidad"
                        className="input-field input-field-providers"
                        name="cantidad_disponible"
                        value={formData.cantidad_disponible}
                        onChange={handleInputChange}
                    />
                    {errors.cantidad_disponible && <small className="p-error">{errors.cantidad_disponible}</small>}

                </div>

                {formData.costo &&
                    <>

                        <div className="form-group-markup">
                            <span className="form-label">Mark Up 1 (%)</span>
                            <InputText
                                placeholder="Mark up 1 (%)"
                                className="input-field input-field-providers"
                                name="mark_up_1"
                                value={formData.mark_up_1}
                                onChange={handleInputChange}
                                type='number'
                            />
                            {errors.mark_up_1 && <small className="p-error">{errors.mark_up_1}</small>}
                            {!errors.mark_up_1 && formData.mark_up_1 && <small> <b>Precio 1:</b> { formatMoney((parseFloat(formData.costo) + formData.costo * formData.mark_up_1 / 100).toFixed(2))}</small>}

                        </div>
                        <div className="form-group-markup">
                            <span className="form-label">Mark Up 2 (%)</span>
                            <InputText
                                placeholder="Mark up 2 (%)"
                                className="input-field input-field-providers"
                                name="mark_up_2"
                                value={formData.mark_up_2}
                                onChange={handleInputChange}
                                type='number'
                            />
                            {errors.mark_up_2 && <small className="p-error">{errors.mark_up_2}</small>}
                            {!errors.mark_up_2 && formData.mark_up_2 && <small><b>Precio 2:</b> { formatMoney((parseFloat(formData.costo) + formData.costo * formData.mark_up_2 / 100).toFixed(2))}</small>}

                        </div>
                        <div className="form-group-markup">
                            <span className="form-label">Mark Up 3 (%)</span>
                            <InputText
                                placeholder="Mark up 3 (%)"
                                className="input-field input-field-providers"
                                name="mark_up_3"
                                value={formData.mark_up_3}
                                onChange={handleInputChange}
                                type='number'
                            />
                            {errors.mark_up_3 && <small className="p-error">{errors.mark_up_3}</small>}
                            {!errors.mark_up_3 && formData.mark_up_3 && <small><b>Precio 3:</b> { formatMoney((parseFloat(formData.costo) + formData.costo * formData.mark_up_3 / 100).toFixed(2))}</small>}
                        </div>
                    </>}

                <div className="form-group">
                    <span className="form-label">IVA</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                        <label>
                            <input
                                type="radio"
                                name="iva"
                                value="10.5"
                                checked={formData.iva === 10.5}
                                onChange={(e) => setFormData(prev => ({ ...prev, iva: parseFloat(e.target.value) }))}
                            />
                            10,5%
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="iva"
                                value="21"
                                checked={formData.iva === 21}
                                onChange={(e) => setFormData(prev => ({ ...prev, iva: parseFloat(e.target.value) }))}
                            />
                            21%
                        </label>
                    </div>
                    {/* {errors.iva && <small className="p-error">{errors.iva}</small>} */}
                </div>

            </div>
            {message && <div style={{ color: 'green', marginTop: '10px' }}>{message}</div>} {/* Mensaje de respuesta */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: "12px" }}>
                {selectedArticle &&
                    <Button label="Eliminar" onClick={handleDelete} icon="pi pi-trash" className="p-button-text" />
                }
                <Button label="Cancelar" onClick={close} icon="pi pi-times" className="p-button-text" />
                <Button label="Aceptar" onClick={onAccept} icon="pi pi-check" className="p-button-text" />
            </div>
        </div>
    );
};

export default FormArticle;
