import React, { useEffect, useState } from 'react';
import Articles from '../articles/Articles';
import BudgetOptions from './BudgetOptions';
import BudgetClient from './BudgetClient';
import BudgetTotal from './BudgetTotal';
import AddProductInBudget from './AddProductInBudget';
import { useGeneral } from '../../context/GeneralContext';

const Budget = () => {
    const {headerHeight} = useGeneral()


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: `calc(100vh - ${headerHeight}px)`, // Altura restante después del header
        }}>
            {/* Contenedor superior */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
            }}>
                <AddProductInBudget />
                <BudgetClient />
            </div>

            {/* Contenedor desplazable para Articles */}
            <div style={{
                flex: 1, // Hacer que este contenedor ocupe el espacio restante
                overflowY: 'auto', // Permitir desplazamiento vertical
            }}>
                <Articles isBudget={true} />
            </div>

            {/* Total y opciones de presupuesto */}
            <div style={{
                padding: '20px',
                backgroundColor: '#f9f9f9', // Color de fondo opcional
                boxShadow: '0 -2px 5px rgba(0,0,0,0.1)', // Sombra para destacar
            }}>
                <BudgetTotal />
                <BudgetOptions />
            </div>
        </div>
    );
};

export default Budget;
